<template>
  <div class="unit-wrap common-tab">
    <el-radio-group v-model="nowRadio" class="m-radio-group" @change="radioChange">
      <el-radio-button label="HGZG">焊工资格</el-radio-button>
      <el-radio-button label="HGXM">合格项目</el-radio-button>
      <el-radio-button label="HGBH">焊工编号</el-radio-button>
    </el-radio-group>

    <div class="scrap-wrap" v-show="nowRadio === 'HGZG'">
      <div class="top">
        <el-row style="padding: 10px 10px 10px 10px;border-bottom: 1px solid #dde3eb;">

          <el-col :span="5">
            <el-select filterable clearable v-model="topSearchForm.userId" @change="topSearch"
                       placeholder="焊工姓名" style="width: 95%">
              <el-option
                v-for="item in allHG"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="5">
            <el-select filterable clearable v-model="topSearchForm.projectId" @change="topSearch"
                       placeholder="合格项目" style="width: 95%">
              <el-option
                v-for="item in allXM"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-col>

          <el-col :span="5">
            <el-date-picker
              @change="topSearch"
              clearable
              style="width: 95%"
              v-model="topSearchForm.time"
              value-format="yyyy-MM"
              type="month"
              placeholder="选择月">
            </el-date-picker>
          </el-col>

          <el-col :span="5">
            <el-button type="primary" size="small" style="background:#607FFF" @click="topSearch">查询</el-button>
            <el-button size="small" @click="resetTopSearch">重置</el-button>
          </el-col>
        </el-row>
      </div>
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          columns-key="TTT-HGZG"
          v-model="functionHeader.searchVal"
          search-title="请输入焊工编号或名称"
          import-name="HGZGImport"
          :tags="!exportSelectionIds.length ?  functionHeader.tags :[]"
          @search="$refs.mTable.setTableData(true)"
        >
        </FunctionHeader>
        <MTable columns-key="TTT-HGZG" ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
          <div slot="action" slot-scope="{ row,$index }">
            <template v-if="permission('Edit')">
              <el-button type="text" @click="onHandle('edit',row)">编辑</el-button>
            </template>
            <template v-if="permission('Del')">
              <el-button type="text" @click="onHandle('del',row)">删除</el-button>
            </template>
          </div>
        </MTable>
      </div>
    </div>

    <div class="scrap-wrap" v-show="nowRadio === 'HGXM'">
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          columns-key="TTT-HGXM"
          import-name="HGXMImport"
          v-model="functionHeader.searchVal"
          search-title="请输入项目编号/名称"
          @search="$refs.mTable2.setTableData(true)"
        >
        </FunctionHeader>
        <MTable columns-key="TTT-HGXM" ref="mTable2" :columns="columns2" :height="height" :set-data-method="getTableData">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        </MTable>
      </div>
    </div>

    <div class="scrap-wrap" v-show="nowRadio === 'HGBH'">
      <div class="bottom">
        <FunctionHeader
          ref="functionHeader"
          columns-key="TTT-HGBH"
          import-name="HGBHImport"
          v-model="functionHeader.searchVal"
          search-title="请输入焊工编号/名称"
          @search="$refs.mTable3.setTableData(true)"
        >
        </FunctionHeader>
        <MTable columns-key="TTT-HGBH" ref="mTable3" :columns="columns3" :height="height" :set-data-method="getTableData">
          <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        </MTable>
      </div>
    </div>

    <MDialog v-model="visiable" :title="currentType" @onOk="addOrEditSubmit">
      <el-row style="padding: 10px;" :gutter="20">
        <el-col :span="12" style="margin-bottom: 20px">
          <div style="display: flex;justify-content: flex-start;align-items: center">
            <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
              *姓名
            </label>
            <div style="flex: 1">
              <el-select filterable v-model="dataForAdd.welderId" placeholder="焊工姓名" style="width: 95%">
                <el-option
                  v-for="item in allHG"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>

        <el-col :span="12" style="margin-bottom: 20px">
          <div style="display: flex;justify-content: flex-start;align-items: center">
            <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
              *合格项目
            </label>
            <div style="flex: 1">
              <el-select filterable v-model="dataForAdd.welderProjectId" placeholder="合格项目" style="width: 95%">
                <el-option
                  v-for="item in allXM"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>

        <el-col :span="12" style="margin-bottom: 20px">
          <div style="display: flex;justify-content: flex-start;align-items: center">
            <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
              *发证日期
            </label>
            <div style="flex: 1">
              <el-date-picker
                style="width: 95%"
                v-model="dataForAdd.issueDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="发证日期">
              </el-date-picker>
            </div>
          </div>
        </el-col>

        <el-col :span="12" style="margin-bottom: 20px">
          <div style="display: flex;justify-content: flex-start;align-items: center">
            <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
              *到期日期
            </label>
            <div style="flex: 1">
              <el-date-picker
                style="width: 95%"
                v-model="dataForAdd.expirationDate"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="到期日期">
              </el-date-picker>
            </div>
          </div>
        </el-col>

        <el-col :span="12" style="margin-bottom: 20px">
          <div style="display: flex;justify-content: flex-start;align-items: center">
            <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
              备注
            </label>
            <div style="flex: 1">
              <el-input style="width: 95%" v-model="dataForAdd.remark" type="text"></el-input>
            </div>
          </div>
        </el-col>
      </el-row>
    </MDialog>
  </div>
</template>

<script>
import {columns,columns2,columns3} from './columns'
import api from '@/api/toolManagement/toolLeader/index.js'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import FunctionHeader from '@/components/FunctionHeader/index'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import DelPopover from "@/components/DelPopover/index.vue";
import {Encrypt} from "@/utils/sercet";
import {getUUid} from "@/utils";

export default {
  name: 'WelderQualifications',
  components: {DelPopover, NewPageOpen, FunctionHeader},
  mixins: [conventionMixin, batchExportFront],
  data() {
    return {
      nowRadio:"HGZG",
      api,
      visiable: false,
      columns,
      columns2,
      columns3,
      dataForAdd: {},
      searchKeyword: true,
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: {type: 'primary', size: 'small'},
            style: {width: 'auto', background: '#607FFF'},
            innerText: '新增',
            on: {
              click: this.addOne
            }
          }
        ],
        importName:'111',
        exportName: 'exportShift',
        exportParams: {}
      },
      currentType: '新增焊工资格',
      isPageLoaded: false,
      total: 0,
      topSearchForm: {
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
      allXM:[],
      allHG:[],
      finalSrc:{}
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 335 - 10 + 35
    },
    columnsKey() {
      return this.$route.name + this.nowRadio
    },
  },
  watch: {},
  async created() {
    await this.getAllxm()
    await this.getAllhg()
  },
  activated() {
    if (!this.isPageLoaded) {
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
  },
  mounted() {
    this.isPageLoaded = true
  },
  methods: {
    radioChange(e) {
      if(e === 'HGZG'){
        this.$refs.mTable.setTableData()
      }else if(e === 'HGXM'){
        this.$refs.mTable2.setTableData()
      }else if(e === 'HGBH'){
        this.$refs.mTable3.setTableData()
      }
    },
    topSearch() {
      const allSrc = []
      if(this.topSearchForm.userId){
        allSrc.push({
          id: getUUid(),
          fieldName: 'welderId',
          fieldType: 'number',
          fieldValue: this.topSearchForm.userId,
          operator: 'eq'
        })
      }
      if(this.topSearchForm.projectId){
        allSrc.push({
          id: getUUid(),
          fieldName: 'welderProjectId',
          fieldType: 'number',
          fieldValue: this.topSearchForm.projectId,
          operator: 'eq'
        })
      }
      if(this.topSearchForm.time){
        allSrc.push({
          id: getUUid(),
          fieldName: 'expirationDate',
          fieldType: 'string',
          fieldValue: this.topSearchForm.time,
          operator: 'like'
        })
      }
      if(allSrc.length){
        this.finalSrc = {
          searchVal: Encrypt(JSON.stringify(allSrc))
        }
      }else{
        this.finalSrc = {
          searchVal: ''
        }
      }
      this.$refs.mTable.setTableData()
    },
    resetTopSearch() {
      this.topSearchForm = {}
      this.topSearch()
    },
    async addOrEditSubmit(callback) {
      if(!this.dataForAdd.welderId){
        this.$message.error('请选择焊工')
        callback(false)
        return
      }
      if(!this.dataForAdd.welderProjectId){
        this.$message.error('请选择项目')
        callback(false)
        return
      }
      if(!this.dataForAdd.issueDate){
        this.$message.error('请选择发证日期')
        callback(false)
        return
      }
      if(!this.dataForAdd.expirationDate){
        this.$message.error('请选择到期日期')
        callback(false)
        return
      }

      if (this.dataForAdd.id) {
        consoleSelf.info("修改工具数据", this.dataForAdd)
        const res = await api.editWelderQualification({...this.dataForAdd})
        if (res) {
          this.$message.success('修改成功')
          this.visiable = false
          this.$refs.mTable.setTableData(true)
        }
      } else {
        consoleSelf.info("新增数据", this.dataForAdd)
        const res = await api.addWelderQualification({...this.dataForAdd})
        if (res) {
          this.$message.success('新增成功')
          this.visiable = false
          this.$refs.mTable.setTableData(true)
        }
      }
      callback(true)
    },
    addOne() {
      this.dataForAdd = {}
      this.currentType = "新增焊工资格"
      this.visiable = true
    },
    onHandle(type, row) {
      if (type === 'edit') {
        this.dataForAdd = {...row};
        this.currentType = "修改焊工资格"
        this.visiable = true
      } else if (type === 'del') {
        this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await api.delWelderQualification(row.id)
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        })
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const {page} = condition
      if(this.nowRadio === 'HGZG'){
        const dd = {
          page: page.currentPage,
          limit: page.pageSize,
          ...this.finalSrc
        }
        if(this.functionHeader.searchVal){
          dd.searchKeyword = this.functionHeader.searchVal
        }
        const res = await api.getWelderQualificationListPage(dd)
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      }else if(this.nowRadio === 'HGXM'){
        const res = await api.getWelderProjectListPage({
          page: page.currentPage,
          size: page.pageSize
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      }else if(this.nowRadio === 'HGBH'){
        const res = await api.getWelderListPage({
          page: page.currentPage,
          size: page.pageSize
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
          this.total = res.total
        }
        callback()
      }
    },
    async getAllxm() {
      const res = await api.getWelderProjectListPage({
        page: 1,
        size: 2000
      })
      if (res) {
        this.allXM = res.records
        console.log("项目",this.allXM)
      }
    },
    async getAllhg() {
      const res = await api.getWelderListPage({
        page: 1,
        size: 2000
      })
      if (res) {
        this.allHG = res.records
        console.log("焊工",this.allHG)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;

  .bottom {
    padding: 10px;
  }

  .m-radio-group {
    margin-left: 10px;
  }
}

::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }

  .el-form-item__content {
    height: 32px;
  }
}
</style>
