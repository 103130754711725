<template>
  <div class="scrap-wrap">
    <div class="top">
      <el-row style="padding: 10px 10px 10px 10px;border-bottom: 1px solid #dde3eb;">

        <el-col :span="5">
          <el-input style="width: 95%" v-model="topSearchForm.code" type="text" placeholder="工单编号"><</el-input>
        </el-col>

        <el-col :span="5">
          <el-input style="width: 95%" v-model="topSearchForm.code" type="text" placeholder="物料编码或名称"><</el-input>
        </el-col>

        <el-col :span="5">
          <el-input style="width: 95%" v-model="topSearchForm.code" type="text" placeholder="焊缝号"><</el-input>
        </el-col>

        <el-col :span="5">
          <el-button type="primary" size="small" style="background:#607FFF" @click="topSearch">查询</el-button>
          <el-button size="small" @click="resetTopSearch">重置</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="bottom">
      <FunctionHeader
        ref="functionHeader"
        columns-key="TTT-GYQD"
        v-model="functionHeader.searchVal"
        search-title="请输入工艺规程编号搜索"
        import-name="GYQDImport"
        :tags="!exportSelectionIds.length ?  functionHeader.tags :[]"
        @search="$refs.mTable.setTableData(true)"
      >
      </FunctionHeader>
      <MTable columns-key="TTT-GYQD" ref="mTable" :columns="columns" :height="height" :set-data-method="getTableData">
        <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
        <div slot="action" slot-scope="{ row,$index }">
          <template v-if="permission('Edit')">
            <el-button type="text" @click="onHandle('edit',row)">编辑</el-button>
          </template>
          <template v-if="permission('Del')">
            <el-button type="text" @click="onHandle('del',row)">删除</el-button>
          </template>
        </div>
      </MTable>
    </div>

    <MDialog v-model="visiable" :title="currentType" width="1000px" top="30px" @onOk="addOrEditSubmit">
      <h3 style="margin-top: 0;margin-bottom: 10px">工单信息</h3>
      <el-row style="padding: 10px;" :gutter="20">
        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">*工单编号</label>
            <div style="flex: 1">
              <el-select filterable v-model="dataForAdd.orderCode" @change="getGD"
                         placeholder="工单编号" style="width: 98%">
                <el-option
                  v-for="item in allGD"
                  :key="item.orderId"
                  :label="item.orderCode + '-' + item.materialsCode + '-' + item.materialsName"
                  :value="item.orderCode">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">物料编号</label>
            <div style="flex: 1">
              <el-input disabled v-model="dataForAdd.materialsCode" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">物料名称</label>
            <div style="flex: 1">
              <el-input disabled v-model="dataForAdd.materialsName" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>
      </el-row>

      <h3 style="margin-top: 0;margin-bottom: 10px">焊缝信息</h3>
      <el-row style="padding: 10px;" :gutter="20">
        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">*焊缝号</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.name" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">母材材质</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.name" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">母材厚度</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.name" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div style="display: flex;justify-content: flex-start;align-items: center">
            <label style="width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px">
              *工艺规程编号
            </label>
            <div style="flex: 1">
              <el-select filterable v-model="dataForAdd.welderProjectId"
                         placeholder="合格项目" style="width: 95%">
                <el-option
                  v-for="item in allXM"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">焊接方法</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.name" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>

        <el-col :span="8" style="margin-bottom: 20px">
          <div class="ttt">
            <label class="ttt-label">标准</label>
            <div style="flex: 1">
              <el-input v-model="dataForAdd.name" type="text" style="width: 95%"></el-input>
            </div>
          </div>
        </el-col>
      </el-row>
    </MDialog>
  </div>
</template>

<script>
import {columns} from './columns'
import api from '@/api/toolManagement/toolLeader/index.js'
import conventionMixin from '@/mixin/conventional-page'
import batchExportFront from '@/mixin/batch-export-front'
import FunctionHeader from '@/components/FunctionHeader/index'
import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
import DelPopover from "@/components/DelPopover/index.vue";
import {Encrypt} from "@/utils/sercet";
import {getUUid} from "@/utils";
import {getToken} from "@/utils/auth";

export default {
  name: 'WeldingProcessSpecification',
  components: {DelPopover, NewPageOpen, FunctionHeader},
  mixins: [conventionMixin, batchExportFront],
  data() {
    return {
      api,
      visiable: false,
      columns,
      dataForAdd: {imageUrl:''},
      searchKeyword: true,
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('Add'),
            tagName: 'el-button',
            props: {type: 'primary', size: 'small'},
            style: {width: 'auto', background: '#607FFF'},
            innerText: '新增',
            on: {
              click: this.addOne
            }
          }
        ],
        importName:'111',
        exportName: 'exportShift',
        exportParams: {}
      },
      currentType: '新增焊接工艺规程',
      isPageLoaded: false,
      total: 0,
      topSearchForm: {
      },
      options: [
        {
          value: '选项1',
          label: '黄金糕'
        }, {
          value: '选项2',
          label: '双皮奶'
        }, {
          value: '选项3',
          label: '蚵仔煎'
        }, {
          value: '选项4',
          label: '龙须面'
        }, {
          value: '选项5',
          label: '北京烤鸭'
        }],
      allXM:[],
      allHG:[],
      allGD:[],
      allGYGC:[],
      finalSrc:{},
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: { token: getToken() },
        showFileList: false,
        accept: 'image/*',
        beforeUpload: this.beforeUpload,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange
      },
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 335 - 10 + 35
    },
  },
  watch: {},
  async created() {
    await this.getAllGD()
    await this.getAllGYGC()
  },
  activated() {
    if (!this.isPageLoaded) {
      this.$refs.mTable.setTableData()
    }
    this.isPageLoaded = false
  },
  mounted() {
    this.isPageLoaded = true
  },
  methods: {
    topSearch() {
      const allSrc = []
      if(this.topSearchForm.userId){
        allSrc.push({
          id: getUUid(),
          fieldName: 'welderId',
          fieldType: 'number',
          fieldValue: this.topSearchForm.userId,
          operator: 'eq'
        })
      }
      if(this.topSearchForm.projectId){
        allSrc.push({
          id: getUUid(),
          fieldName: 'welderProjectId',
          fieldType: 'number',
          fieldValue: this.topSearchForm.projectId,
          operator: 'eq'
        })
      }
      if(this.topSearchForm.time){
        allSrc.push({
          id: getUUid(),
          fieldName: 'expirationDate',
          fieldType: 'string',
          fieldValue: this.topSearchForm.time,
          operator: 'like'
        })
      }
      if(allSrc.length){
        this.finalSrc = {
          searchVal: Encrypt(JSON.stringify(allSrc))
        }
      }else{
        this.finalSrc = {
          searchVal: ''
        }
      }
      this.$refs.mTable.setTableData()
    },
    resetTopSearch() {
      this.topSearchForm = {}
      this.topSearch()
    },
    async addOrEditSubmit(callback) {
      if (this.dataForAdd.id) {
        consoleSelf.info("修改工具数据", this.dataForAdd)
        const res = await api.editWelderQualification({...this.dataForAdd})
        if (res) {
          this.$message.success('修改成功')
          this.visiable = false
          this.$refs.mTable.setTableData(true)
        }
      } else {
        consoleSelf.info("新增数据", this.dataForAdd)
        const res = await api.addWelderQualification({...this.dataForAdd})
        if (res) {
          this.$message.success('新增成功')
          this.visiable = false
          this.$refs.mTable.setTableData(true)
        }
      }
      callback(true)
    },
    addOne() {
      this.dataForAdd = {imageUrl:''}
      this.currentType = "新增焊工资格"
      this.visiable = true
    },
    onHandle(type, row) {
      if (type === 'edit') {
        this.dataForAdd = {...row};
        this.currentType = "修改焊工资格"
        this.visiable = true
      } else if (type === 'del') {
        this.$confirm('确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const res = await api.delWelderQualification(row.id)
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData(true)
          }
        })
      }
    },
    // 列表
    async getTableData(condition, callback) {
      const {page} = condition
      const dd = {
        page: page.currentPage,
        limit: page.pageSize,
        ...this.finalSrc
      }
      if(this.functionHeader.searchVal){
        dd.searchKeyword = this.functionHeader.searchVal
      }
      const res = await api.getWelderQualificationListPage(dd)
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
        this.total = res.total
      }
      callback()
    },
    async getAllxm() {
      const res = await api.getWelderProjectListPage({
        page: 1,
        size: 2000
      })
      if (res) {
        this.allXM = res.records
        console.log("项目",this.allXM)
      }
    },
    async getAllhg() {
      const res = await api.getWelderListPage({
        page: 1,
        size: 2000
      })
      if (res) {
        this.allHG = res.records
        console.log("焊工",this.allHG)
      }
    },
    getGD(e){
      const a = this.allGD.find(item => item.orderCode === e)
      console.log(a)
      this.dataForAdd.materialsCode = a.materialsCode
      this.dataForAdd.materialsName = a.materialsName
    },
    async getAllGD() {
      const res = await api.getWorkOrder({
        page: 1,
        size: 2000
      })
      debugger
      if (res) {
        this.allGD = res.records.map((item) => {
          return {
            orderId:item.id,
            orderCode:item.productionPlanNumber,
            materialsId:item.materialsId,
            materialsCode:item.materialsCode,
            materialsName:item.materialsName
          }
        })
        console.log("工单",this.allGD)
      }
    },
    async getAllGYGC() {
      const res = await api.getWeldProcessRegulationListPage({
        page: 1,
        size: 2000
      })
      debugger
      if (res) {
        this.allGYGC = res.records
        console.log("工艺规程",this.allHG)
      }
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        debugger
        this.$message.success('上传成功')
        this.$set(this.dataForAdd, 'imageUrl', res.result)
      } else {
        this.$message.error(res.errorMsg)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.function-header-wrap {
  padding-top: 0;
}

.ttt{
  display: flex;justify-content: flex-start;align-items: center;

  .ttt-label{
    width: 100px;text-align: right;padding-right: 10px;line-height: 32px;font-size: 14px;
  }
}

.avatar {
  width: 128px;
  height: 128px;
  display: block;
}

.scrap-wrap {
  width: 100%;
  padding-top: 10px;

  .bottom {
    padding: 10px;
  }

  .m-radio-group {
    margin-left: 10px;
  }
}

::v-deep {
  .e-divider {
    margin: 0px 0 0 0;
  }

  .el-form-item__content {
    height: 32px;
  }

  .avatar-uploader{
    .el-upload {
      border: 1px dashed #d9d9d9!important;
      border-radius: 6px;
      cursor: pointer;
      position: relative;
      overflow: hidden;

      &:hover {
        border-color: #409EFF;
      }

      .avatar-uploader-icon {
        font-size: 28px;
        color: #8c939d;
        width: 38px;
        height: 38px;
        line-height: 38px;
        text-align: center;
      }
    }
  }
}
</style>
