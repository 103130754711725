var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.type === "process"
      ? _c("div", [
          _vm.data.processFlowVersionId
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "process-info" },
                  [
                    _vm._v(" 工艺路线：" + _vm._s(_vm.baseFormData.name) + " "),
                    _c("el-tag", { attrs: { size: "small" } }, [
                      _vm._v(_vm._s(_vm.processFlowVersion.version))
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "process-detail-body" }, [
                  _c("div", { staticClass: "detail-card" }, [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("基本信息")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _c(
                          "el-row",
                          { staticClass: "view-list", attrs: { gutter: 24 } },
                          _vm._l(_vm.columns.base, function(item, index) {
                            return _c(
                              "el-col",
                              {
                                key: item.prop,
                                staticClass: "ellipsis",
                                style: {
                                  marginBottom: index !== 4 ? "20px" : 0
                                },
                                attrs: { title: _vm.getValue(item), span: 6 }
                              },
                              [
                                _c("span", [_vm._v(_vm._s(item.label) + "：")]),
                                _c("span", [_vm._v(_vm._s(_vm.getValue(item)))])
                              ]
                            )
                          }),
                          1
                        )
                      ],
                      1
                    )
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "detail-card",
                      staticStyle: { "margin-top": "10px" }
                    },
                    [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("工艺路线图")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot process-card" },
                        [
                          _c("ProcessFlow", {
                            ref: "processFlow",
                            attrs: {
                              "read-only": true,
                              "concise-process-flow": true,
                              "process-flow-detail": _vm.processFlowVersion
                            }
                          })
                        ],
                        1
                      )
                    ]
                  )
                ])
              ])
            : _c(
                "div",
                { staticStyle: { "padding-top": "50px" } },
                [
                  _c("MBlank", { attrs: { title: "请前往物料配置配置工艺！" } })
                ],
                1
              )
        ])
      : _vm._e(),
    _vm.type === "drawAndFile"
      ? _c("div", [
          (_vm.memberType === "mom" && _vm.currentType != "add") ||
          (_vm.memberType === "line" && _vm.isShowDrawAndFile)
            ? _c("div", { staticClass: "detail-card" }, [
                _vm.mCode === "fsd"
                  ? _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _vm._m(0),
                        _c("br"),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            "show-page": false,
                            height: 300,
                            columns:
                              _vm.memberType === "mom"
                                ? _vm.columns.momFiles
                                : _vm.columns.lineFiles,
                            data: _vm.fsdWlList
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "fileName",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _vm._v(" " + _vm._s(row.fname) + " "),
                                    _c("img", {
                                      staticStyle: {
                                        width: "11px",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/information/procedure/附件@2x.png")
                                      }
                                    })
                                  ])
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          cursor: "pointer",
                                          color: "#607FFF",
                                          "font-weight": "bold"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toUrl(row)
                                          }
                                        }
                                      },
                                      [_vm._v("查看")]
                                    )
                                  ])
                                }
                              }
                            ],
                            null,
                            false,
                            4069979783
                          )
                        }),
                        _vm._m(1),
                        _c("br"),
                        _vm.data.id && _vm.memberType === "mom"
                          ? _c(
                              "el-upload",
                              _vm._b(
                                {
                                  staticClass: "b20",
                                  attrs: { type: "primary" }
                                },
                                "el-upload",
                                _vm.uploadProps,
                                false
                              ),
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.uploadKey = "filesList"
                                      }
                                    }
                                  },
                                  [_vm._v("上传附件")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            "show-page": false,
                            height: 300,
                            columns:
                              _vm.memberType === "mom"
                                ? _vm.columns.momFiles
                                : _vm.columns.lineFiles,
                            data: _vm.data.attachment
                              ? JSON.parse(_vm.data.attachment)
                              : []
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "fileName",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#607FFF" },
                                        attrs: {
                                          href: row.url,
                                          download: row.name,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v(_vm._s(row.name))]
                                    ),
                                    _c("img", {
                                      staticStyle: {
                                        width: "11px",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/information/procedure/附件@2x.png")
                                      }
                                    })
                                  ])
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c("DelPopover", {
                                        on: {
                                          onOk: function(callback) {
                                            return _vm.delFiles(
                                              "filesList",
                                              row,
                                              callback
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3182914179
                          )
                        })
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _vm._m(2),
                        _c("br"),
                        _vm.data.id && _vm.memberType === "mom"
                          ? _c(
                              "el-upload",
                              _vm._b(
                                {
                                  staticClass: "b20",
                                  attrs: { type: "primary" }
                                },
                                "el-upload",
                                _vm.uploadProps,
                                false
                              ),
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.uploadKey = "drawingsList"
                                      }
                                    }
                                  },
                                  [_vm._v("上传图纸")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            "show-page": false,
                            height: 300,
                            columns:
                              _vm.memberType === "mom"
                                ? _vm.columns.momFiles
                                : _vm.columns.lineFiles,
                            data: _vm.data.drawing
                              ? JSON.parse(_vm.data.drawing)
                              : []
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "fileName",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#607FFF" },
                                        attrs: {
                                          href: row.url,
                                          download: row.name,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v(_vm._s(row.name))]
                                    ),
                                    _c("img", {
                                      staticStyle: {
                                        width: "11px",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/information/procedure/附件@2x.png")
                                      }
                                    })
                                  ])
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c("DelPopover", {
                                        on: {
                                          onOk: function(callback) {
                                            return _vm.delFiles(
                                              "drawingsList",
                                              row,
                                              callback
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3904723685
                          )
                        }),
                        _vm._m(3),
                        _c("br"),
                        _vm.data.id && _vm.memberType === "mom"
                          ? _c(
                              "el-upload",
                              _vm._b(
                                {
                                  staticClass: "b20",
                                  attrs: { type: "primary" }
                                },
                                "el-upload",
                                _vm.uploadProps,
                                false
                              ),
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "primary" },
                                    on: {
                                      click: function($event) {
                                        _vm.uploadKey = "filesList"
                                      }
                                    }
                                  },
                                  [_vm._v("上传附件")]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            "show-page": false,
                            height: 300,
                            columns:
                              _vm.memberType === "mom"
                                ? _vm.columns.momFiles
                                : _vm.columns.lineFiles,
                            data: _vm.data.attachment
                              ? JSON.parse(_vm.data.attachment)
                              : []
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "index",
                                fn: function(ref) {
                                  var $index = ref.$index
                                  return _c("div", {}, [
                                    _vm._v(_vm._s($index + 1))
                                  ])
                                }
                              },
                              {
                                key: "fileName",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c("div", {}, [
                                    _c(
                                      "a",
                                      {
                                        staticStyle: { color: "#607FFF" },
                                        attrs: {
                                          href: row.url,
                                          download: row.name,
                                          target: "_blank"
                                        }
                                      },
                                      [_vm._v(_vm._s(row.name))]
                                    ),
                                    _c("img", {
                                      staticStyle: {
                                        width: "11px",
                                        "margin-left": "5px"
                                      },
                                      attrs: {
                                        src: require("@/assets/information/procedure/附件@2x.png")
                                      }
                                    })
                                  ])
                                }
                              },
                              {
                                key: "action",
                                fn: function(ref) {
                                  var row = ref.row
                                  return _c(
                                    "div",
                                    {},
                                    [
                                      _c("DelPopover", {
                                        on: {
                                          onOk: function(callback) {
                                            return _vm.delFiles(
                                              "filesList",
                                              row,
                                              callback
                                            )
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                }
                              }
                            ],
                            null,
                            false,
                            3182914179
                          )
                        })
                      ],
                      1
                    )
              ])
            : _c(
                "div",
                { staticStyle: { "padding-top": "50px" } },
                [
                  _c("MBlank", {
                    attrs: { title: "请前往物料配置配置图纸及附件！" }
                  })
                ],
                1
              )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bt20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: {
          src: require("@/assets/information/procedure/其他附件@2x.png")
        }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("其他附件")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "bt20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: {
          src: require("@/assets/information/procedure/其他附件@2x.png")
        }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("其他附件")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }