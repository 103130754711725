var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "unit-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          staticStyle: { display: "none" },
          on: { change: _vm.radioChange },
          model: {
            value: _vm.params.radio,
            callback: function($$v) {
              _vm.$set(_vm.params, "radio", $$v)
            },
            expression: "params.radio"
          }
        },
        [
          _vm.permission("SupplierList")
            ? _c("el-radio-button", { attrs: { label: "Supplier" } }, [
                _vm._v("供应商")
              ])
            : _vm._e(),
          _vm.permission("CustomerList")
            ? _c("el-radio-button", { attrs: { label: "Customer" } }, [
                _vm._v("客户")
              ])
            : _vm._e()
        ],
        1
      ),
      _c("FunctionHeader", {
        attrs: {
          tags: _vm.functionHeader.tags,
          "search-title": _vm.searchTitle,
          radio: _vm.params.radio,
          "columns-key": _vm.columnsKey,
          "import-name": _vm.functionHeader.importName,
          "export-name": _vm.functionHeader.exportName,
          "export-params": _vm.functionHeader.exportParams
        },
        on: {
          search: function($event) {
            return _vm.$refs.mTable.setTableData(true)
          }
        },
        model: {
          value: _vm.functionHeader.searchVal,
          callback: function($$v) {
            _vm.$set(_vm.functionHeader, "searchVal", $$v)
          },
          expression: "functionHeader.searchVal"
        }
      }),
      _vm.permission(_vm.params.radio + "List")
        ? _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.columns[_vm.params.radio],
              height: _vm.height,
              "columns-key": _vm.columnsKey,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "index",
                  fn: function(ref) {
                    var $index = ref.$index
                    return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                  }
                },
                {
                  key: "type",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", {}, [
                      _c("div", [
                        _vm._v(
                          _vm._s(
                            row.type === 0
                              ? "寄售"
                              : row.type === 1
                              ? "自有"
                              : "-"
                          )
                        )
                      ])
                    ])
                  }
                },
                {
                  key: "isEnabled",
                  fn: function(ref) {
                    var row = ref.row
                    return _c("div", { staticClass: "is-enabled" }, [
                      _c("div", {
                        style: {
                          background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                        }
                      }),
                      _c("div", [
                        _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                      ])
                    ])
                  }
                },
                {
                  key: "action",
                  fn: function(ref) {
                    var row = ref.row
                    return _c(
                      "div",
                      {},
                      [
                        _vm.permission(_vm.params.radio + "Status")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.switchStatus(
                                      row,
                                      _vm.params.radio
                                    )
                                  }
                                }
                              },
                              [_vm._v(_vm._s(row.isEnabled ? "停用" : "启用"))]
                            )
                          : _vm._e(),
                        _vm.permission(_vm.params.radio + "Status")
                          ? _c("el-divider", {
                              attrs: { direction: "vertical" }
                            })
                          : _vm._e(),
                        _vm.permission(_vm.params.radio + "Modify")
                          ? _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.modifyForm(row, "modify")
                                  }
                                }
                              },
                              [_vm._v("修改")]
                            )
                          : _vm._e(),
                        _vm.permission(_vm.params.radio + "Modify")
                          ? _c("el-divider", {
                              attrs: { direction: "vertical" }
                            })
                          : _vm._e(),
                        _vm.permission(_vm.params.radio + "Del")
                          ? _c("DelPopover", {
                              on: {
                                onOk: function(callback) {
                                  return _vm.del(row, callback)
                                }
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  }
                }
              ],
              null,
              false,
              2837394669
            )
          })
        : _vm._e(),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.messages[_vm.currentType] },
          on: { onOk: _vm.submitForm },
          model: {
            value: _vm.visible,
            callback: function($$v) {
              _vm.visible = $$v
            },
            expression: "visible"
          }
        },
        [
          _c(
            "MFormBuilder",
            {
              ref: "formBuild",
              attrs: {
                "form-data": _vm.formData,
                "form-list": _vm.formList[_vm.params.radio]
              }
            },
            [
              _c(
                "div",
                { attrs: { slot: "drawing" }, slot: "drawing" },
                [
                  _c(
                    "el-upload",
                    _vm._b(
                      {
                        ref: "upload",
                        staticClass: "upload-demo",
                        attrs: { "file-list": _vm.fileList }
                      },
                      "el-upload",
                      _vm.uploadProp,
                      false
                    ),
                    [
                      _c(
                        "el-button",
                        { attrs: { size: "small", type: "primary" } },
                        [_vm._v("点击上传")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "el-upload__tip",
                          attrs: { slot: "tip" },
                          slot: "tip"
                        },
                        [_vm._v("支持扩展名：.xls .xlsx")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }