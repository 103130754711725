var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "batch-number-wrap common-tab" },
    [
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("FunctionHeader", {
            attrs: {
              "search-title": "请输入成品序列号",
              tags: _vm.functionHeader.tags
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              columns: _vm.fastColumns,
              height: _vm.height,
              "columns-setting": false,
              "set-data-method": _vm.getTableData
            },
            on: { "selection-change": _vm.selectionChange },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "status",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", {}, [
                    _vm._v(_vm._s(row.status === 1 ? "无效" : "有效"))
                  ])
                }
              },
              {
                key: "batchNumber",
                fn: function(ref) {
                  var row = ref.row
                  return _c("div", { staticClass: "batch-number-columns" }, [
                    _c("span", [_vm._v(_vm._s(row.batchNumber))]),
                    _vm.currentBatchNumberList.indexOf(row.batchNumber) !== -1
                      ? _c("img", {
                          attrs: {
                            src: require("../../../../assets/information/新@2x.png")
                          }
                        })
                      : _vm._e()
                  ])
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "div",
                    {},
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.printCode(row)
                            }
                          }
                        },
                        [_vm._v("打印")]
                      ),
                      _vm.permission("Del")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("Del")
                        ? _c("DelPopover", {
                            on: {
                              onOk: function(callback) {
                                return _vm.del(row, callback)
                              }
                            }
                          })
                        : _vm._e(),
                      _vm.permission("Cancel")
                        ? _c("el-divider", { attrs: { direction: "vertical" } })
                        : _vm._e(),
                      _vm.permission("Cancel")
                        ? _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                disabled: row.status === 1
                              },
                              on: {
                                click: function($event) {
                                  return _vm.cancel(row)
                                }
                              }
                            },
                            [_vm._v("作废")]
                          )
                        : _vm._e()
                    ],
                    1
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c("PrintCode", {
        ref: "printCode",
        attrs: { "common-id": _vm.commonId }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }