var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "materials-wrap" }, [
    _c("div", { staticClass: "materials-detail-header" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v("MBOM详情")]
          )
        ]
      )
    ]),
    _c(
      "div",
      { staticClass: "bottom" },
      [
        _c(
          "Split",
          {
            attrs: { offset: _vm.split.offset, height: _vm.splitHeight },
            on: {
              "update:offset": function($event) {
                return _vm.$set(_vm.split, "offset", $event)
              }
            }
          },
          [
            _c("template", { slot: "left" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOpenOne,
                      expression: "isOpenOne"
                    }
                  ],
                  staticStyle: { height: "calc(100% - 40px)" }
                },
                [
                  _c("BOMLevel", {
                    attrs: {
                      mySelfShowFlag: false,
                      materialsBomId: _vm.materialsBomId,
                      type: "mbom"
                    },
                    on: {
                      nodeClick: _vm.setMaterialsClassId,
                      changeWidth: _vm.changeWidths
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOpen,
                      expression: "isOpen"
                    }
                  ],
                  staticStyle: { "margin-top": "20px" }
                },
                [
                  _c("BOMLevel", {
                    attrs: {
                      mySelfShowFlag: false,
                      materialsBomId: _vm.materialsBomId,
                      type: "warnMoreM",
                      view: true
                    },
                    on: { changeWidth: _vm.changeWidths }
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.isOpen,
                      expression: "isOpen"
                    }
                  ],
                  staticClass: "divFixed",
                  staticStyle: { cursor: "pointer" },
                  attrs: { title: "点击收起" },
                  on: {
                    click: function($event) {
                      return _vm.changeWidths(false)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-d-arrow-left" })]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.isOpen,
                      expression: "!isOpen"
                    }
                  ],
                  staticClass: "divFixed",
                  staticStyle: { cursor: "pointer" },
                  attrs: { title: "点击展开" },
                  on: {
                    click: function($event) {
                      return _vm.changeWidths(true)
                    }
                  }
                },
                [_c("i", { staticClass: "el-icon-d-arrow-right" })]
              )
            ]),
            _c("template", { slot: "right" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showType == "Materials",
                      expression: "showType == 'Materials'"
                    }
                  ],
                  staticClass: "materials-info"
                },
                [
                  _c("FunctionHeader", {
                    ref: "functionHeader",
                    attrs: {
                      "search-title": "请输入名称/编号/规格",
                      tags: _vm.functionHeader.tags,
                      "export-name": _vm.functionHeader.exportName,
                      "export-params": _vm.functionHeader.exportParams
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  }),
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      columns: _vm.columnsDetail,
                      height: _vm.height,
                      "set-data-method": _vm.getTableData
                    },
                    on: { "selection-change": _vm.selectionChange },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "drawing",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                row.picture
                                  ? _c("el-image", {
                                      staticClass: "drawing-img",
                                      attrs: {
                                        src: row.picture,
                                        "preview-src-list": [row.picture]
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        },
                        {
                          key: "code",
                          fn: function(ref) {
                            var row = ref.row
                            return _vm.isShowChild
                              ? _c("NewPageOpen", {
                                  attrs: {
                                    path: {
                                      name: "bomDetail",
                                      query: { id: row.id, type: "view" }
                                    },
                                    text: row.code
                                  }
                                })
                              : _vm._e()
                          }
                        },
                        {
                          key: "isEnabled",
                          fn: function(ref) {
                            var row = ref.row
                            return _c("div", { staticClass: "is-enabled" }, [
                              _c("div", {
                                style: {
                                  background: row.isEnabled
                                    ? "#24CAD7"
                                    : "#FFCC78"
                                }
                              }),
                              _c("div", [
                                _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                              ])
                            ])
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var row = ref.row
                            return _c(
                              "div",
                              {},
                              [
                                _vm.permission("Modify")
                                  ? _c("NewPageOpen", {
                                      attrs: {
                                        path: {
                                          name: "materialsDetail",
                                          query: { id: row.id, type: "modify" }
                                        },
                                        text: "编辑"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          }
                        }
                      ],
                      null,
                      true
                    )
                  })
                ],
                1
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showType == "Process",
                      expression: "showType == 'Process'"
                    }
                  ],
                  staticClass: "process-detail-body"
                },
                [
                  _c("div", { staticClass: "right1" }, [
                    _c("div", { staticClass: "detail-card" }, [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("基本信息")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot" },
                        [
                          [
                            _c(
                              "el-row",
                              {
                                staticClass: "view-list",
                                attrs: { gutter: 24 }
                              },
                              _vm._l(_vm.getBaseColumns, function(item, index) {
                                return _c(
                                  "el-col",
                                  {
                                    key: item.prop,
                                    style: {
                                      marginBottom: index !== 6 ? "20px" : 0,
                                      display: "inline-flex"
                                    },
                                    attrs: {
                                      title: _vm.getValue(item),
                                      span: 8
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "word-break": "keep-all"
                                        }
                                      },
                                      [_vm._v(_vm._s(item.label) + "：")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          "word-break": "break-all"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.getValue(item)))]
                                    )
                                  ]
                                )
                              }),
                              1
                            )
                          ]
                        ],
                        2
                      )
                    ]),
                    _c("div", { staticClass: "detail-card" }, [
                      _c("div", { staticClass: "card-tit" }, [
                        _vm._v("工艺路线图")
                      ]),
                      _c(
                        "div",
                        { staticClass: "card-bot process-card" },
                        [
                          _c("process-flow", {
                            ref: "processFlow",
                            attrs: {
                              "base-form": _vm.$refs.baseForm,
                              "read-only": true,
                              "process-flow-detail": _vm.processFlowVersion,
                              "handle-type": _vm.params.type,
                              "can-import": _vm.baseFormData.isEnabled
                            },
                            on: {
                              addDetail: _vm.addDetails,
                              hideLoading: function($event) {
                                _vm.buttonLoading = false
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ])
                ]
              ),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showType == "Procedure",
                      expression: "showType == 'Procedure'"
                    }
                  ]
                },
                [_c("ProcedureDetail", { ref: "procedureDetail" })],
                1
              )
            ])
          ],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }