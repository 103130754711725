export const columns = [
  {
    fixed: 'left',
    width: 80,
    prop: 'index',
    label: '序号',
    slotName: 'index',
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: '编码',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'standard',
    label: '标准',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'number',
    label: '工艺规程编号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'welderProjectNames',
    label: '焊工资格',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'evaluationNumber',
    label: '工艺评定号',
    sortable: false,
    hiddenSearch: true,
  },
  {
    prop: 'baseMaterial',
    label: '母材材质',
    sortable: false,
    hiddenSearch: true,
  },
  {prop: 'firstThickness', label: '母材厚度1', sortable: false, hiddenSearch: true},
  {prop: 'secondThickness', label: '母材厚度2', sortable: false, hiddenSearch: true},
  {prop: 'diagramUrl', label: '焊接简图', sortable: false, hiddenSearch: true,slotName: 'diagramUrl'},
  {prop: 'preheatTemperature', label: '预热温度(℃)', sortable: false, hiddenSearch: true},
  {prop: 'interlayerTemperature', label: '层间温度(℃)', sortable: false, hiddenSearch: true},
  {prop: 'heatTreatment', label: '母材焊后热处理', sortable: false, hiddenSearch: true},
  {
    fixed: 'right',
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]

export const logColumns = [
  {slotName: 'layer',prop: 'layer', label: '层次', sortable: false, hiddenSearch: true},
  {slotName: 'weldMethod',prop: 'weldMethod', label: '焊接方法', sortable: false, hiddenSearch: true},
  {slotName: 'weldMaterialNumber',prop: 'weldMaterialNumber', label: '焊接牌号', sortable: false, hiddenSearch: true},
  {slotName: 'specifications',prop: 'specifications', label: '规格(mm)', sortable: false, hiddenSearch: true},
  {slotName: 'polarity',prop: 'polarity', label: '极性', sortable: false, hiddenSearch: true},
  {slotName: 'current',prop: 'current', label: '电流(A)', sortable: false, hiddenSearch: true},
  {slotName: 'voltage',prop: 'voltage', label: '电压(V)', sortable: false, hiddenSearch: true},
  {slotName: 'speed',prop: 'speed', label: '速度(cm/min)', sortable: false, hiddenSearch: true},
  {slotName: 'shieldingGasType',prop: 'shieldingGasType', label: '保护气种类', sortable: false, hiddenSearch: true},
  {slotName: 'gasFlow',prop: 'gasFlow', label: '气体流量(L/min)', sortable: false, hiddenSearch: true},
  {slotName: 'shieldingGasPurity',prop: 'shieldingGasPurity', label: '保护气纯度', sortable: false, hiddenSearch: true},
  {slotName: 'backGasFlow',prop: 'backGasFlow', label: '背气流量', sortable: false, hiddenSearch: true},
  {slotName: 'dryingTemperature',prop: 'dryingTemperature', label: '烘干温度(℃)', sortable: false, hiddenSearch: true},
  {slotName: 'holdingTime',prop: 'holdingTime', label: '保温时间(H)', sortable: false, hiddenSearch: true},
  {slotName: 'dosage',prop: 'dosage', label: '用量(kg)', sortable: false, hiddenSearch: true},
  {
    fixed: 'right',
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]

