var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "procedure-detail-wrapper" }, [
    _c(
      "div",
      { staticClass: "procedure-detail-body" },
      [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c(
                "el-row",
                { staticClass: "view-list", attrs: { gutter: 24 } },
                [
                  _vm._l(_vm.fixData(_vm.columnss.bases), function(
                    item,
                    index
                  ) {
                    return _c(
                      "el-col",
                      {
                        key: item.prop,
                        style: { marginBottom: index !== 4 ? "20px" : 0 },
                        attrs: { span: 6 }
                      },
                      [
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(item.label) + "：")
                        ]),
                        _c("span", { staticClass: "bot-tis" }, [
                          _vm._v(_vm._s(_vm.getValue(item)))
                        ])
                      ]
                    )
                  }),
                  _vm.memberType === "line"
                    ? _c(
                        "el-col",
                        { style: { margin: "20px 0" }, attrs: { span: 24 } },
                        [
                          _c(
                            "div",
                            { staticClass: "flex-mode" },
                            [
                              _c("div", { staticClass: "tit" }, [
                                _vm._v("默认下发方式：")
                              ]),
                              _c(
                                "el-radio-group",
                                {
                                  attrs: { name: "assingGroups", disabled: "" },
                                  model: {
                                    value: _vm.selectParams.issueMode,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.selectParams,
                                        "issueMode",
                                        $$v
                                      )
                                    },
                                    expression: "selectParams.issueMode"
                                  }
                                },
                                _vm._l(_vm.issList, function(items) {
                                  return _c(
                                    "el-radio",
                                    {
                                      key: items.value,
                                      attrs: { label: items.value }
                                    },
                                    [_vm._v(_vm._s(items.name))]
                                  )
                                }),
                                1
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.memberType === "line"
                    ? _c("el-col", { attrs: { span: 24 } }, [
                        _c(
                          "div",
                          { staticClass: "flex-mode" },
                          [
                            _c("div", { staticClass: "tit" }, [
                              _vm._v("默认指派方式：")
                            ]),
                            _c(
                              "el-radio-group",
                              {
                                attrs: { name: "assingGroup", disabled: "" },
                                model: {
                                  value: _vm.selectParams.assignMode,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.selectParams,
                                      "assignMode",
                                      $$v
                                    )
                                  },
                                  expression: "selectParams.assignMode"
                                }
                              },
                              _vm._l(_vm.assList, function(items) {
                                return _c(
                                  "el-radio",
                                  {
                                    key: items.value,
                                    attrs: { label: items.value }
                                  },
                                  [_vm._v(_vm._s(items.name))]
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      ])
                    : _vm._e(),
                  _vm.memberType === "line"
                    ? _c("el-col", { attrs: { span: 24 } }, [
                        _c("div", { staticClass: "flex-modes" }, [
                          _c(
                            "div",
                            { staticClass: "tit" },
                            [
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content:
                                      "工单仅一个工序时公差控制报工数量生效，多于一个工序时不生效",
                                    placement: "top"
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "el-icon-question"
                                  })
                                ]
                              ),
                              _vm._v(" 工序报工公差设置： ")
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "mode-ri" },
                            [
                              _c(
                                "el-select",
                                {
                                  staticClass: "ri-select",
                                  attrs: {
                                    size: "small",
                                    placeholder: "请选择",
                                    disabled: ""
                                  },
                                  model: {
                                    value: _vm.differenceType,
                                    callback: function($$v) {
                                      _vm.differenceType = $$v
                                    },
                                    expression: "differenceType"
                                  }
                                },
                                _vm._l(_vm.procedureDifferenceTypes, function(
                                  item
                                ) {
                                  return _c("el-option", {
                                    key: item.id,
                                    attrs: { label: item.name, value: item.id }
                                  })
                                }),
                                1
                              ),
                              _c("div", { staticClass: "ri-top" }, [
                                _c("div", { staticClass: "top-tit" }, [
                                  _vm._v("上公差：")
                                ]),
                                _c("div", { staticClass: "top-tit" }, [
                                  _vm._v(_vm._s(_vm.differencePlus))
                                ])
                              ]),
                              _c("div", { staticClass: "ri-top" }, [
                                _c("div", { staticClass: "top-tit" }, [
                                  _vm._v("下公差：")
                                ]),
                                _c("div", { staticClass: "top-tit" }, [
                                  _vm._v(_vm._s(_vm.differenceMinus))
                                ])
                              ])
                            ],
                            1
                          )
                        ])
                      ])
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ]),
        !["mom"].includes(_vm.memberCode)
          ? _c("div", { staticClass: "detail-card" }, [
              _c("div", { staticClass: "card-tit" }, [_vm._v("可执行工位")]),
              _c(
                "div",
                { staticClass: "card-bot" },
                [
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      "show-page": false,
                      height: 300,
                      columns: _vm.columnss.station,
                      data: _vm.stationList
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "index",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                          }
                        },
                        {
                          key: "action",
                          fn: function(ref) {
                            var $index = ref.$index
                            return _c(
                              "div",
                              {},
                              [
                                _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      type: "text",
                                      disabled: !_vm.saveButton
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.delFrontData(
                                          "stationList",
                                          $index
                                        )
                                      }
                                    }
                                  },
                                  [_vm._v("删除")]
                                )
                              ],
                              1
                            )
                          }
                        }
                      ],
                      null,
                      false,
                      1425241456
                    )
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("工序操作描述")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _c("el-input", {
                attrs: {
                  type: "textarea",
                  placeholder: "请输入工序操作描述",
                  maxlength: "500",
                  disabled: !_vm.saveButton,
                  "show-word-limit": ""
                },
                model: {
                  value: _vm.operationDesc,
                  callback: function($$v) {
                    _vm.operationDesc = $$v
                  },
                  expression: "operationDesc"
                }
              })
            ],
            1
          )
        ]),
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("工序图纸及附件")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              _vm._m(0),
              _c("br"),
              _vm.saveButton
                ? _c(
                    "el-upload",
                    _vm._b(
                      { staticClass: "b20", attrs: { type: "primary" } },
                      "el-upload",
                      _vm.uploadProps,
                      false
                    )
                  )
                : _vm._e(),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  "show-page": false,
                  height: 300,
                  columns: _vm.columnss.drawings,
                  data: _vm.drawingsList
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "fileName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#607FFF" },
                            attrs: {
                              href: row.url,
                              download: row.name,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(row.name))]
                        ),
                        _c("img", {
                          staticStyle: { width: "11px", "margin-left": "5px" },
                          attrs: {
                            src: require("@/assets/information/procedure/附件@2x.png")
                          }
                        })
                      ])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                disabled: !_vm.saveButton
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delFrontData(
                                    "drawingsList",
                                    $index
                                  )
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              }),
              _c("div", { staticClass: "bt20 flex-sbc" }, [
                _c("img", {
                  staticStyle: { width: "18px" },
                  attrs: {
                    src: require("@/assets/information/procedure/其他附件@2x.png")
                  }
                }),
                _c(
                  "span",
                  {
                    staticClass: "l10",
                    staticStyle: { position: "relative", top: "2px" },
                    on: {
                      click: function($event) {
                        _vm.uploadKey = "filesList"
                      }
                    }
                  },
                  [_vm._v("其他附件")]
                )
              ]),
              _c("br"),
              _c("MTable", {
                ref: "mTable",
                attrs: {
                  "show-page": false,
                  height: 300,
                  columns: _vm.columnss.files,
                  data: _vm.filesList
                },
                scopedSlots: _vm._u([
                  {
                    key: "index",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                    }
                  },
                  {
                    key: "fileName",
                    fn: function(ref) {
                      var row = ref.row
                      return _c("div", {}, [
                        _c(
                          "a",
                          {
                            staticStyle: { color: "#607FFF" },
                            attrs: {
                              href: row.url,
                              download: row.name,
                              target: "_blank"
                            }
                          },
                          [_vm._v(_vm._s(row.name))]
                        ),
                        _c("img", {
                          staticStyle: { width: "11px", "margin-left": "5px" },
                          attrs: {
                            src: require("@/assets/information/procedure/附件@2x.png")
                          }
                        })
                      ])
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "text",
                                disabled: !_vm.saveButton
                              },
                              on: {
                                click: function($event) {
                                  return _vm.delFrontData("filesList", $index)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ],
                        1
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ]),
        _vm.memberType === "line"
          ? _c("div", { staticClass: "detail-card" }, [
              _c("div", { staticClass: "card-tit" }, [_vm._v("工序上传参数")]),
              _c(
                "div",
                { staticClass: "card-bot" },
                [
                  _c("ProcedureParams", {
                    ref: "procedureParams",
                    attrs: {
                      "read-only": !_vm.saveButton,
                      "group-list": _vm.groupList
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e(),
        !["bbelc"].includes(_vm.memberCode)
          ? [
              _vm.procedureType === 1
                ? _c("div", { staticClass: "detail-card" }, [
                    _c("div", { staticClass: "card-tit" }, [
                      _vm._v("外协工序时间设置")
                    ]),
                    _c(
                      "div",
                      { staticClass: "card-bot" },
                      [
                        _c(
                          "span",
                          { staticStyle: { "padding-left": "10px" } },
                          [_vm._v("外协默认周期：")]
                        ),
                        _c("el-input-number", {
                          staticStyle: { width: "200px" },
                          attrs: {
                            size: "small",
                            "controls-position": "right",
                            min: 0,
                            step: 1,
                            disabled: !_vm.saveButton
                          },
                          model: {
                            value: _vm.outsourceTime,
                            callback: function($$v) {
                              _vm.outsourceTime = $$v
                            },
                            expression: "outsourceTime"
                          }
                        }),
                        _c(
                          "span",
                          { staticStyle: { "padding-left": "10px" } },
                          [_vm._v("天")]
                        )
                      ],
                      1
                    )
                  ])
                : _vm._e()
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "b20 flex-sbc" }, [
      _c("img", {
        staticStyle: { width: "18px" },
        attrs: { src: require("@/assets/information/procedure/图纸@2x.png") }
      }),
      _c(
        "span",
        {
          staticClass: "l10",
          staticStyle: { position: "relative", top: "2px" }
        },
        [_vm._v("图纸")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }