<template>
  <div class="edit-materials-wrapper">
    <!--    抽屉-->
    <MFormBuilder
      ref="formBuild"
      :col-span="6"
      :form-data="formData"
      :form-list="getFormList"
      :custom-rules="rules"
      :disabled="formDisabled"
    >
      <!--        单位管理-->
      <div slot="unit">
        <div class="detail-card">
          <div class="card-tit" style="padding-left: 24px">
            <span style="padding-right: 10px">单位管理</span>
            <el-checkbox v-model="dialogForm.showEnableUnit" @change="showEnableUnit">只显示启用单位</el-checkbox>
          </div>
          <div class="card-bot" style="padding: 15px">
            <div v-if="!formDisabled">
              <el-button type="primary" @click="addDialogFormInfo('unit')">新增辅助单位</el-button>
            </div>
            <div style="padding-top: 10px">
              <MTable
                ref="mUnitTable"
                :height="300"
                :show-page="false"
                :data="form.materialsUnitList"
                :columns="dialogTColumns.unit"
              >
                <div slot="type" slot-scope="{ row }" :class="row.type === 'main' ? 'required-div red' : ''">
                  {{ row.type === 'main' ? '主单位' : '辅助单位' }}
                </div>
                <div slot="name" slot-scope="{ row }">
                  <el-form-item v-if="row.type === 'main'" prop="mainUnitId">
                    <el-select
                      v-model="row.mainUnitId"
                      placeholder="请选择"
                      style="width: 100%"
                      :disabled="currentType === 'modify'"
                      filterable
                      @change="setMainUnitName"
                    >
                      <el-option
                        v-for="item in formOptions.materialsUnitList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                  <div v-else>{{ row.name }}</div>
                </div>
                <div slot="isMappingEnable" slot-scope="{ row }">{{ row.isMappingEnable ? '启用' : '停用' }}</div>
                <div v-if="row.type !== 'main'" slot="calc" slot-scope="{ row }">1{{ row.name }} =
                  {{ row.conversionFactor }}{{ form.mainUnitName }}
                </div>
                <div v-if="row.type !== 'main'" slot="action" slot-scope="{ row, $index }">
                  <el-button type="text" @click="switchDialogStatus(row, $index)">{{ row.isMappingEnable ? '停用' : '启用' }}
                  </el-button>
                  <el-divider direction="vertical" />
                  <el-button type="text" @click="modifyDialog(row, 'unit', $index)">修改</el-button>
                  <el-divider direction="vertical" />
                  <el-button type="text" @click="delDialog($index)">删除</el-button>
                </div>
              </MTable>
            </div>
          </div>
        </div>
      </div>
      <!--        重量关系-->
      <div slot="weightRelative">
        <el-row :gutter="20">
          <el-col :span="2">1</el-col>
          <el-col :span="4" class="unit-name">{{ form.mainUnitName }}</el-col>
          <el-col :span="2">=</el-col>
          <el-col :span="8">
            <el-input-number
              v-model="form.weightConversionFactor"
              controls-position="right"
              style="width: 100%"
              :disabled="!weightRelative"
              @change="(val) => setForm('weightConversionFactor', val)"
            />
          </el-col>
          <el-col :span="8">
            <el-select
              v-model="form.weightUnit"
              :disabled="!weightRelative"
              placeholder="请选择"
              style="width: 100%"
              @change="(val) => setForm('weightUnit', val)"
            >
              <el-option label="毫克(mg)" value="毫克(mg)" />
              <el-option label="克(g)" value="克(g)" />
              <el-option label="千克(kg)" value="千克(kg)" />
              <el-option label="吨(t)" value="吨(t)" />
            </el-select>
          </el-col>
        </el-row>
      </div>
      <!--        包装信息-->
      <div slot="package">
        <div class="detail-card">
          <div class="card-tit" style="padding-left: 24px">
            <span class="label-title" style="padding-right: 10px">包装信息</span>
            <el-checkbox v-model="dialogForm.showEnablePackage" @change="showEnablePackage">只显示启用包装</el-checkbox>
          </div>
          <div class="card-bot" style="padding: 15px">
            <div v-if="!formDisabled" :span="24">
              <el-button type="primary" @click="addDialogFormInfo('package')">新增包装信息</el-button>
            </div>
            <div :span="24" style="padding-top: 10px">
              <MTable
                ref="mPackageTable"
                :height="300"
                :show-page="false"
                :data="form.containerClassList"
                :columns="dialogTColumns.package"
              >
                <div slot="isMappingEnable" slot-scope="{ row }">{{ row.isMappingEnable ? '启用' : '停用' }}</div>
                <div slot="calc" slot-scope="{ row }">1{{ row.containerUnitName }} = {{ row.conversionFactor }}{{ form.mainUnitName }}
                </div>
                <div slot="action" slot-scope="{ row, $index }">
                  <el-button type="text" @click="switchDialogStatus(row, $index)">{{ row.isMappingEnable ? '停用' : '启用' }}
                  </el-button>
                  <el-button type="text" @click="modifyDialog(row, 'package', $index)">修改</el-button>
                  <el-button type="text" @click="delDialog($index)">删除</el-button>
                </div>
              </MTable>
            </div>
          </div>
        </div>
      </div>
      <!--        工艺路线-->
      <div slot="process">
        <StaticInfo v-if="currentTab === 'process'" type="process" :data="formData" />
      </div>
      <!--        图纸及附件-->
      <div slot="drawAndFile">
        <StaticInfo v-if="currentTab === 'drawAndFile'" type="drawAndFile" :data="formData" :member-type="memberType" :currentType="currentType"/>
      </div>
      <!--来料检-->
      <!-- <div slot="inspectionInComing">
        <StaticInfo type="inspectionInComing" :data="formData" :member-type="memberType"/>
      </div> -->
      <!--        bom-->
      <div slot="bom">
        <MTable
          v-if="list.length"
          ref="mTable"
          default-expand-all
          :is-tree-data="true"
          :columns="columnsAll"
          :height="300"
          :show-page="false"
          :data="list"
          :columns-setting="false"
          :tree-props="{children: 'childBomList'}"
        >
          <div slot="index" slot-scope="{ row, $index }">{{ $index + 1 }}</div>
          <div slot="quantityStandard" slot-scope="{ row }">{{ fixData(row.quantityStandard) }}</div>
        </MTable>
        <div v-else style="padding-top: 50px">
          <MBlank title="请前往物料配置配置图纸及附件！" />
        </div>
      </div>
    </MFormBuilder>
    <!--    辅助单位新增-->
    <MDialog
      v-model="dialogVisible"
      :title="dialogMessage[currentDialogType]+dialogMessage[dialogType]"
      :append-to-body="true"
      @onOk="dialogSubmit"
    >
      <MFormBuilder
        ref="dialogFormBuild"
        :form-data="dialogFormData"
        :form-list="dialogFormList[dialogType]"
      >
        <div slot="packageName">
          <el-select v-model="packageName" placeholder="请选择包装器名称" clearable style="width: 100%" @change="packageChange">
            <el-option
              v-for="item in formOptions.containerClassList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
              <span style="float: left">{{ item.name }} / {{ item.specification }}</span>
            </el-option>
          </el-select>
        </div>
        <div slot="unitConversion">
          <el-row :gutter="10">
            <el-col :span="1">1</el-col>
            <el-col :span="4" class="unit-name">{{ dialogForm.unitName }}</el-col>
            <el-col :span="1">=</el-col>
            <el-col :span="10">
              <el-input-number
                v-model="dialogForm.conversionFactor"
                controls-position="right"
                style="width: 100%"
                :min="0"
                @change="(val) => setDialogForm('conversionFactor', val)"
              />
            </el-col>
            <el-col :span="8">{{ form.mainUnitName }}(主单位)</el-col>
          </el-row>
        </div>
      </MFormBuilder>
    </MDialog>
  </div>
</template>

<script>
import StaticInfo from '@/views/information/materials/components/static-info'
import { packageFormList, unitFormList, getFunctionList, formList } from './form-list-copy'
import api from '@/api/information/materials'
import {
  getFunctionList as columnGetFunctionList,
  packageColumns,
  unitColumns,
  columnsAll
} from '@/views/information/materials/columns'
import { getUUid } from '@/utils'
import maApi from '@/api/information/materials-attribute'
import modelApi from '@/api/information/warehouse/model'
import { Encrypt } from '@/utils/sercet'
import packageApi from '@/api/information/warehouse/package'
import mApi from '@/api/information/materials-attribute'

export default {
  name: 'EditMaterialsCopy',
  components: { StaticInfo },
  data() {
    return {
      columnsAll,
      dialogVisible: false,
      currentType: 'add',
      currentDialogType: 'add',
      dialogFormData: {},
      dialogFormList: {
        unit: unitFormList,
        package: packageFormList
      },
      dialogType: 'unit',
      dialogForm: {
        showEnableUnit: false,
        showEnablePackage: false,
        conversionFactor: '',
        unitName: ''
      },
      packageName: '',
      originDialogForm: {},
      formList,
      rules: {
        mainUnitId: [{ required: true, message: '请选择主单位', trigger: 'blur' }],
        weightConversionFactor: [{ required: false, message: '请选择重量关系', trigger: 'blur' }]
      },
      formData: {},
      originForm: {},
      form: {
        originMaterialsUnitList: [],
        materialsUnitList: [{ type: 'main', isMappingEnable: 1 }],
        originContainerClassList: [],
        containerClassList: [],
        weightConversionFactor: '',
        weightUnit: '',
        mainUnitId: '',
        mainUnitName: ''
      },
      materialsMessage: {
        add: '新增物料信息',
        modify: '修改物料信息',
        copy: '复制物料信息',
        del: '删除物料信息',
        view: '查看物料信息'
      },
      dialogMessage: {
        package: '包装信息',
        unit: '辅助单位',
        'add': '新增',
        'modify': '修改',
        'del': '删除',
        'enable': '启用',
        'disable': '停用'
      },
      defaultSearch: {
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }]
        ))
      },
      currentIndex: 0,
      weightRelative: false,
      formDisabled: false,
      currentTab: 'inventory',
      dialogTColumns: {
        unit: unitColumns,
        package: packageColumns
      },
      visible: false,
      formOptions: {
        materialsTypeList: [],
        materialsUnitList: [],
        materialsClassList: [],
        wareHouseList: [],
        materialsQualityList: [],
        containerClassList: [],
        materialsAttributeList: []
      },
      list: []
    }
  },
  async beforeMount() {
    this.originForm = this._.cloneDeep(this.form)
    this.originDialogForm = this._.cloneDeep(this.dialogForm)
    getFunctionList({
      isWeightManagerChange: this.isWeightManagerChange,
      isQualityManagerChange: this.isQualityManagerChange,
      tabClick: this.tabClick
    })
    columnGetFunctionList({
      unitChange: this.unitChange
      // packageChange: this.packageChange
    })
  },
  computed: {
    memberType(){
      return localStorage.getItem('memberType')
    },
    getFormList(){
      let cloneData = this._.cloneDeep(this.formList)
      if (localStorage.getItem('memberType') === 'line') {
        return cloneData
      } else {
        cloneData[0].children.pop()
        cloneData[0].children[0].children[0].children = cloneData[0].children[0].children[0].children.slice(0, 4)
        return cloneData
      }
    }
  },
  mounted() {
    if(this.memberType === 'line'){
      this.getMaterialsQualityList()
    }
    // this.getWareHouseList()
    this.getContainerClassList()
    this.getUnitList()
  },
  methods: {
    fixData(val) {
      const number = String(val).replace(/^(.*\..{4}).*$/, '$1')
      return Number(number)
    },
    tabClick(val) {
      this.currentTab = val.name
    },
    // 获取bom
    async getBom(id) {
      const res = await mApi.getMaterialsBomList(id, 1)
      if (res) {
        this.list = res
      }
    },
    // 获取物料材质
    async getMaterialsQualityList() {
      const res = await maApi.getMaterialsQualityList(this.defaultSearch)
      if (res) {
        this.formOptions.materialsQualityList = res
        this.formList[0].children[0].children[0].children[7].children[0].children[0].children[0].options = res
      }
    },
    // 获取单位列表
    async getUnitList() {
      const res = await maApi.getMaterialsUnitList(this.defaultSearch)
      if (res) {
        this.formOptions.materialsUnitList = res
        this.dialogFormList.unit[0].children[0].options = res
      }
    },
    // 获取包装容器
    async getContainerClassList() {
      const res = await packageApi.getContainerClassList(this.defaultSearch)
      if (res) {
        this.formOptions.containerClassList = res
        this.dialogFormList.package[0].children[0].options = res
      }
    },
    // 获取仓库列表
    async getWareHouseList() {
      const res = await modelApi.getRepositoryModelList({
        searchVal: Encrypt(JSON.stringify(
          [{
            id: getUUid(),
            fieldName: 'isEnabled',
            fieldType: 'number',
            fieldValue: 1,
            operator: 'eq'
          }, {
            id: getUUid(),
            fieldName: 'type',
            fieldType: 'number',
            fieldValue: 0,
            operator: 'eq'
          }]
        ))
      })
      if (res) {
        this.formOptions.wareHouseList = res
        // this.formList[0].children[0].children[0].children[4].children[0].children[0].children[0].options = res
      }
    },
    // 新增物料
    add() {
      this.currentType = 'add'
      this.formDisabled = false
      this.form = this._.cloneDeep(this.originForm)
      this.formData = { materialsClassId: this.materialsClassId }
      this.createMaterialsCode()
      this.visible = true
    },
    async createMaterialsCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api.createCode({
        count: 1
      })
      if (res) {
        this.$parent.$refs.baseForm.setForm({
          code: res[0]
        })
      }
    },
    // 修改或复制物料
    async modifyOrCopy(row, command) {
      this.getBom(row.id)
      const res = row
      if (res) {
        this.currentType = command
        this.formData = res
        this.form = {
          originMaterialsUnitList: res.materialsUnitList,
          materialsUnitList: [{
            type: 'main',
            isMappingEnable: 1,
            mainUnitId: res.mainUnitId
          }, ...res.materialsUnitList],
          originContainerClassList: res.containerClassList,
          containerClassList: res.containerClassList,
          weightConversionFactor: res.weightConversionFactor,
          weightUnit: res.weightUnit,
          mainUnitId: res.mainUnitId,
          mainUnitName: res.mainUnitName
        }

        if(this.memberType === 'line') this.isQualityManagerChange(res.isQualityManager)
        this.weightRelative = res.isWeightManager === 1
        this.formDisabled = command === 'view'
        this.visible = true
      }
      if (command === 'copy') await this.createMaterialsCode()
    },
    // 重量改变
    isWeightManagerChange(val) {
      this.form.weightUnit = ''
      this.form.weightConversionFactor = ''
      this.weightRelative = val === 1
      if (this.$refs.formBuild) {
        this.$refs.formBuild.setRuleRequired([{
          key: 'weightConversionFactor',
          required: val === 1
        }, {
          key: 'weightUnit',
          required: val === 1
        }])
      }
    },
    isQualityManagerChange(val) {
      this.formList[0].children[0].children[0].children[7].children[0].children[0].props.disabled = val === 0
      if (this.$refs.formBuild) {
        this.$refs.formBuild.setForm({
          materialsQualityId: ''
        })
        this.$refs.formBuild.setRuleRequired([{
          key: 'materialsQualityId',
          required: val === 1
        }])
      }
    },
    modifyDialog(row, type, index) {
      this.currentIndex = index
      this.dialogFormData = row
      this.dialogType = type
      this.currentDialogType = 'modify'
      this.dialogVisible = true
    },
    delDialog(index) {
      this.currentDialogType = 'del'
      if (this.dialogType === 'unit') {
        this.form.materialsUnitList.splice(index, 1)
      }
      if (this.dialogType === 'package') {
        this.form.containerClassList.splice(index, 1)
      }
      this.$message.success(this.getDialogSuccessMessage())
    },
    setDialogForm(key, val) {
      this.$refs.dialogFormBuild.setForm({ [key]: val })
    },
    // 主单位改变
    setMainUnitName(val) {
      this.form.mainUnitId = val
      const current = this.formOptions.materialsUnitList.find(item => item.id === val)
      this.form.materialsUnitList[0].remark = current.remark
      this.form.mainUnitName = current.name
      this.$refs.formBuild.setForm({
        mainUnitId: val,
        mainUnitName: current.name
      })
    },
    // 新增辅助单位
    addDialogFormInfo(type) {
      if (!this.form.mainUnitId) {
        this.$message.info('请先选择主单位')
        return
      }
      this.currentDialogType = 'add'
      this.dialogType = type
      this.dialogFormData = {}
      this.dialogForm = this._.cloneDeep(this.originDialogForm)
      this.packageName = ''
      this.dialogVisible = true
    },
    // 辅助单位变化
    unitChange(val) {
      const current = this.formOptions.materialsUnitList.find(item => item.id === val)
      this.dialogForm.unitName = current.name
      delete current.remark
      delete current.isMappingEnable
      delete current.conversionFactor
      this.$refs.dialogFormBuild.setForm(current)
    },
    // 包装信息变化
    packageChange(val) {
      const current = this.formOptions.containerClassList.find(item => item.id === val)
      this.dialogForm.unitName = current.containerUnitName
      delete current.remark
      delete current.isMappingEnable
      delete current.conversionFactor
      this.$refs.dialogFormBuild.setForm(current)
    },
    // 只显示启用单位
    showEnableUnit(val) {
      if (val) {
        this.form.originMaterialsUnitList = this._.cloneDeep(this.form.materialsUnitList)
        this.form.materialsUnitList = this.form.materialsUnitList
          .filter(item => item.isMappingEnable === 1)
      } else {
        this.form.materialsUnitList = this.form.originMaterialsUnitList
      }
    },
    // 只显示启用包装
    showEnablePackage(val) {
      if (val) {
        this.form.originContainerClassList = this._.cloneDeep(this.form.containerClassList)
        this.form.containerClassList = this.form.containerClassList
          .filter(item => item.isMappingEnable === 1)
      } else {
        this.form.containerClassList = this.form.originContainerClassList
      }
    },
    switchDialogStatus(row, index) {
      this.currentDialogType = row.isMappingEnable ? 'disable' : 'enable'
      if (this.dialogType === 'unit') {
        this.form.materialsUnitList[index].isMappingEnable =
          row.isMappingEnable ? 0 : 1
      }
      if (this.dialogType === 'package') {
        this.form.containerClassList[index].isMappingEnable =
          row.isMappingEnable ? 0 : 1
      }
      this.$message.success(this.getDialogSuccessMessage())
    },
    setForm(key, val) {
      this.$refs.formBuild.setForm({ [key]: val })
    },
    dialogSubmit(callback) {
      const { form } = this.$refs.dialogFormBuild
      console.log('包装1=》', form)
      this.$refs.dialogFormBuild.formRefs().validate(async(valid) => {
        if (valid) {
          if (this.currentDialogType === 'add') {
            const name = this.dialogType === 'unit' ? 'materialsUnitList' : 'containerClassList'
            const names = this.form[name].map(item => item.name)
            if (names.indexOf(form.name) !== -1) {
              this.$message.info(`${form.name}已存在`)
              this.$refs.dialogFormBuild.formRefs().clearValidate()
              callback(false)
              return
            }
            this.form[name].push(form)
            console.log('包装=》', this.form[name])
          }
          if (this.currentDialogType === 'modify') {
            if (this.dialogType === 'unit') {
              this.form.materialsUnitList.splice(this.currentIndex, 1, form)
            } else {
              this.form.containerClassList.splice(this.currentIndex, 1, form)
            }
          }
          this.$refs.dialogFormBuild.formRefs().clearValidate()
          this.dialogVisible = false
        }
        callback(false)
      })
    },
    submitForm(baseForm, callback) {
      const { form } = this.$refs.formBuild
      this.$refs.formBuild.formRefs().validate(async(valid) => {
        if (valid) {
          const res = await api[`${this.currentType}Materials`](
            this.currentType === 'modify' ? this.createResetData(baseForm, form) : this.createSetData(baseForm, form)
          )
          if (res) {
            this.$parent.saveButton = false
            this.$message.success(this.getSuccessMessage())
            if (this.currentType !== 'view') {
              this.$router.push({ query: { type: 'view', id: res }})
              this.params = { type: 'view', id: res }
            }
            this.$parent.getMaterialsDetail()
          }
          callback(true)
        } else {
          callback()
        }
      })
    },
    createSetData(baseForm, raw) {
      const formOptions = { ...this.formOptions, ...this.$parent.formOptions }
      const defaultRepository = formOptions.wareHouseList.find(item => item.id === raw.defaultRepositoryId)
      const materialsType = formOptions.materialsTypeList.find(item => item.id === baseForm.materialsTypeId)
      const materialsQuality = formOptions.materialsQualityList.find(item => item.id === raw.materialsQualityId)
      const materialsAttribute = formOptions.materialsAttributeList.find(item => item.id === baseForm.materialsAttributeId)
      const materialsClassId = Array.isArray(baseForm.materialsClassId)
        ? baseForm.materialsClassId[baseForm.materialsClassId.length - 1] : baseForm.materialsClassId
      const materialsClass = formOptions.materialsClassList
        .find(item => item.id === materialsClassId)
      return {
        isCheck: baseForm.isCheck,
        isKeyMaterials: baseForm.isKeyMaterials,
        purchaseLeadTime: baseForm.purchaseLeadTime,
        code: baseForm.code,
        name: baseForm.name,
        isLimit: (raw.upperLimit || raw.upperLimit === 0 || raw.lowerLimit || raw.lowerLimit === 0) ? 1 : 0,
        upperLimit: raw.upperLimit,
        lowerLimit: raw.lowerLimit,
        specifications: baseForm.specifications,
        mainUnitId: raw.mainUnitId,
        mainUnitName: raw.mainUnitName,
        materialsClassId,
        picture: baseForm.picture,
        materialsClassName: materialsClass && materialsClass.name,
        materialsTypeId: baseForm.materialsTypeId,
        materialsAttributeId: baseForm.materialsAttributeId,
        materialsAttributeName: materialsAttribute && materialsAttribute.name,
        materialsTypeName: materialsType && materialsType.name,
        defaultRepositoryName: defaultRepository && defaultRepository.name,
        defaultRepositoryId: defaultRepository && defaultRepository.id,
        defaultRepositoryCode: defaultRepository && defaultRepository.code,
        isBatchManager: 1,
        sequenceNumber: raw.sequenceNumber,
        isWeightManager: raw.isWeightManager,
        weightConversionFactor: raw.weightConversionFactor,
        weightUnit: raw.weightUnit,
        isQualityManager: raw.isQualityManager,
        materialsQualityId: raw.materialsQualityId,
        materialsQualityName: materialsQuality && materialsQuality.name,
        materialsUnitList: this.form.materialsUnitList.slice(1, this.form.materialsUnitList.length),
        containerClassList: this.form.containerClassList,
        remark: baseForm.remark,
        isEnabled: baseForm.isEnabled,
        iqcInspectId:baseForm.iqcInspectId,
        isExemption:baseForm.isExemption
      }
    },
    createResetData(baseForm, raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(baseForm, raw), obj)
    },
    getSuccessMessage() {
      return `${this.materialsMessage[this.currentType]}成功`
    },
    getDialogSuccessMessage() {
      return `${this.dialogMessage[this.currentDialogType]}成功`
    }
  }
}
</script>

<style scoped lang="scss">
.unit-name {
  height: 32px;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  text-align: center;
  line-height: 32px;
}
div.red {
  color: red;
}
::v-deep {
  div[classDom='tab-center'] {
    .el-tabs__nav {
      position: relative;
      left: 50%;
      transform: translateX(-50%) !important;
    }
  }

}
::v-deep {
  .el-input__inner {
    height: 32px !important;
  }
  .card-title {
    height: 48px;
    background: #E5E7EA;
    border-radius: 4px 4px 0 0;
    line-height: 48px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393D60;
    padding-left: 24px;
  }
  div[classdom^='inventory'], div[classdom^='bgf'] {
    background: #fff;
    margin: 0;
    .el-form-item {
      margin-bottom: 0 !important;
    }
  }
  div[classdom^='inventory'] {
    margin-bottom: 10px;
  }
  div[classdom='inventory-left'] {
    border-radius: 0 0 0 4px;
    .el-form-item {
      padding: 15px 0 15px 15px;
    }
  }
  div[classdom='inventory-center'] {
    .el-form-item {
      padding: 15px 0;
    }
  }
  div[classdom='inventory-right'] {
    border-radius: 0 0 4px 0;
    .el-form-item {
      padding: 15px 15px 15px 0;
    }
  }
  div[classdom='bgf-batch'] {
    .el-form-item {
      padding: 15px 15px 0 15px;
    }
  }
  div[classdom='bgf-sequence'] {
    .el-form-item {
      padding: 15px 15px 0 15px;
    }
  }
  div[classdom='bgf-weight'], div[classdom='bgf-quality-m'] {
    .el-form-item {
      padding: 0 0 0 15px;
    }
  }
  div[classdom='bgf-relative'], div[classdom='bgf-quality'] {
    .el-form-item {
      padding: 0 15px 0 0;
    }
  }
  div[classdom='bgf-quality-m'] {
    margin-bottom: 10px;
    .el-form-item {
      padding-bottom: 15px;
    }
  }
  div[classdom='bgf-quality'] {
    margin-bottom: 10px;
    .el-form-item {
      padding-bottom: 15px;
    }
  }
}
</style>
