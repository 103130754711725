var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap common-tab" },
    [
      _c(
        "el-radio-group",
        {
          staticClass: "m-radio-group",
          on: { change: _vm.radioChange },
          model: {
            value: _vm.changeParam,
            callback: function($$v) {
              _vm.changeParam = $$v
            },
            expression: "changeParam"
          }
        },
        [
          _vm.permission("CommonList")
            ? _c("el-radio-button", { attrs: { label: "Common" } }, [
                _vm._v("普通")
              ])
            : _vm._e(),
          _vm.permission("ReturnFixList") && _vm.memberType === "line"
            ? _c("el-radio-button", { attrs: { label: "ReturnFix" } }, [
                _vm._v("返修")
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("SearchForm", {
            ref: "searchForm",
            attrs: {
              "form-list-extend": _vm.searchFormList,
              "form-data": _vm.searchFormData,
              "default-form": _vm.defaultSearchFormData
            },
            on: { search: _vm.searchTable }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c(
            "FunctionHeader",
            {
              ref: "functionHeader",
              attrs: {
                "search-title": "请输入工艺名称/编码",
                tags: !_vm.exportSelectionIds.length
                  ? _vm.functionHeader.tags
                  : [],
                radio: _vm.changeParam,
                "export-name": _vm.functionHeader.exportName,
                "columns-key": _vm.columnsKey,
                "export-params": _vm.functionHeader.exportParams,
                "show-export": !_vm.exportSelectionIds.length ? true : false
              },
              on: {
                search: function($event) {
                  return _vm.$refs.mTable.setTableData(true)
                }
              },
              model: {
                value: _vm.functionHeader.searchVal,
                callback: function($$v) {
                  _vm.$set(_vm.functionHeader, "searchVal", $$v)
                },
                expression: "functionHeader.searchVal"
              }
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.exportSelectionIds.length,
                      expression: "exportSelectionIds.length"
                    }
                  ],
                  attrs: { slot: "all" },
                  slot: "all"
                },
                [
                  _vm.permission("CommongylxImport")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              _vm.showImport = true
                            }
                          }
                        },
                        [_vm._v("工时导入 ")]
                      )
                    : _vm._e(),
                  _vm.permission("CommongylxExport")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: { click: _vm.exportProcessFlowStandardHours }
                        },
                        [_vm._v(" 工时导出 ")]
                      )
                    : _vm._e(),
                  _vm.permission(_vm.changeParam + "Export")
                    ? _c(
                        "el-button",
                        {
                          attrs: { size: "small", type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.allMore("batchExport")
                            }
                          }
                        },
                        [_vm._v("批量导出 ")]
                      )
                    : _vm._e(),
                  _vm.permission(_vm.changeParam + "Del")
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary", size: "small" },
                          on: {
                            click: function($event) {
                              return _vm.allMore("batchDel")
                            }
                          }
                        },
                        [_vm._v(" 批量删除 ")]
                      )
                    : _vm._e(),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        margin: "0 10px",
                        display: "inline-block",
                        "font-size": "12px",
                        color: "#393D60"
                      }
                    },
                    [
                      _vm._v(" 已选 "),
                      _c("span", { staticStyle: { color: "#607FFF" } }, [
                        _vm._v(_vm._s(_vm.getSelectionDataLength))
                      ]),
                      _vm._v(" / " + _vm._s(_vm.total) + "项 ")
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "text", size: "small" },
                      on: { click: _vm.resetSelectData }
                    },
                    [_vm._v("清空")]
                  )
                ],
                1
              )
            ]
          ),
          _vm.permission(_vm.changeParam + "List")
            ? _c("MTable", {
                ref: "mTable",
                attrs: {
                  columns: _vm.getColumns,
                  height: _vm.height,
                  "columns-key": _vm.columnsKey,
                  "set-data-method": _vm.getTableData
                },
                on: { "selection-change": _vm.selectionChange },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "code",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("NewPageOpen", {
                          attrs: {
                            path: _vm.getDetailPath(row, "view"),
                            text: row.code
                          }
                        })
                      }
                    },
                    {
                      key: "areaIdList",
                      fn: function(ref) {
                        var row = ref.row
                        return row.areaList
                          ? _c(
                              "div",
                              {},
                              _vm._l(row.areaList, function(item) {
                                return _c(
                                  "el-tag",
                                  {
                                    key: item.id,
                                    staticClass: "role-tags",
                                    attrs: { color: "#F6F7F9" }
                                  },
                                  [_vm._v(_vm._s(item.name) + " ")]
                                )
                              }),
                              1
                            )
                          : _vm._e()
                      }
                    },
                    {
                      key: "isEnabled",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", { staticClass: "is-enabled" }, [
                          _c("div", {
                            style: {
                              background: row.isEnabled ? "#24CAD7" : "#FFCC78"
                            }
                          }),
                          _c("div", [
                            _vm._v(_vm._s(row.isEnabled ? "启用" : "停用"))
                          ])
                        ])
                      }
                    },
                    {
                      key: "isPartialOutbound",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", { staticClass: "is-enabled" }, [
                          _c("div", {
                            style: {
                              background: row.isPartialOutbound
                                ? "#24CAD7"
                                : "#FFCC78"
                            }
                          }),
                          _c("div", [
                            _vm._v(
                              _vm._s(row.isPartialOutbound ? "启用" : "禁用")
                            )
                          ])
                        ])
                      }
                    },
                    {
                      key: "overProductionType",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", { staticClass: "is-enabled" }, [
                          _c("div", {
                            style: {
                              background: row.overProductionType
                                ? "#24CAD7"
                                : "#FFCC78"
                            }
                          }),
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                +row.overProductionType === 1 ? "启用" : "禁用"
                              )
                            )
                          ])
                        ])
                      }
                    },
                    {
                      key: "type",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", { staticClass: "is-enabled" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.procedureClass[row.type].name))
                          ])
                        ])
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _vm.permission(_vm.changeParam + "Copy")
                              ? _c("NewPageOpen", {
                                  attrs: {
                                    path: _vm.getDetailPath(row, "copy"),
                                    text: "复制"
                                  }
                                })
                              : _vm._e(),
                            _vm.permission(_vm.changeParam + "Copy")
                              ? _c("el-divider", {
                                  attrs: { direction: "vertical" }
                                })
                              : _vm._e(),
                            _vm.permission(_vm.changeParam + "Status")
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { type: "text" },
                                    on: {
                                      click: function($event) {
                                        return _vm.switchStatus(
                                          row,
                                          _vm.params.radio
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(row.isEnabled ? "停用" : "启用") +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.permission(_vm.changeParam + "Status")
                              ? _c("el-divider", {
                                  attrs: { direction: "vertical" }
                                })
                              : _vm._e(),
                            _c(
                              "el-dropdown",
                              {
                                on: {
                                  command: function(command) {
                                    _vm.more(row, command)
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "el-dropdown-link" },
                                  [
                                    _vm._v(" 更多"),
                                    _c("i", {
                                      staticClass:
                                        "el-icon-arrow-down el-icon--right"
                                    })
                                  ]
                                ),
                                _c(
                                  "el-dropdown-menu",
                                  {
                                    staticClass: "td-dropdown-menu",
                                    attrs: { slot: "dropdown" },
                                    slot: "dropdown"
                                  },
                                  [
                                    _vm.permission(_vm.changeParam + "Modify")
                                      ? _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "modify" } },
                                          [_vm._v("修改 ")]
                                        )
                                      : _vm._e(),
                                    _vm.permission(_vm.changeParam + "Del")
                                      ? _c(
                                          "el-dropdown-item",
                                          { attrs: { command: "del" } },
                                          [_vm._v("删除 ")]
                                        )
                                      : _vm._e()
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      }
                    }
                  ],
                  null,
                  true
                )
              })
            : _vm._e(),
          _c(
            "MDialog",
            {
              attrs: { title: _vm.messages[_vm.currentType] },
              on: { onOk: _vm.submitForm },
              model: {
                value: _vm.visible,
                callback: function($$v) {
                  _vm.visible = $$v
                },
                expression: "visible"
              }
            },
            [
              _c(
                "MFormBuilder",
                {
                  ref: "formBuild",
                  attrs: {
                    "form-data": _vm.formData,
                    "form-list": _vm.getFormList
                  }
                },
                [
                  _c(
                    "div",
                    { attrs: { slot: "processFile" }, slot: "processFile" },
                    [
                      _c("ProcessFile", {
                        ref: "ProcessFile",
                        attrs: {
                          "base-list": _vm.formData.processFiles,
                          "read-only": false
                        }
                      })
                    ],
                    1
                  )
                ]
              )
            ],
            1
          ),
          _c("BatchImport", {
            attrs: {
              visible: _vm.showImport,
              "import-name": "工时导入",
              "show-cover": false
            },
            on: {
              "update:visible": function($event) {
                _vm.showImport = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }