export const columns = [
  {
    prop: 'index',
    label: '序号',
    fixed: 'left',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {prop: 'name', label: '工单编号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '物料编号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '物料名称', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '容器类别', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '焊缝号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '筒节号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '材质', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '内径', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '厚度', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '高度', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '下料总长', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '下料总宽', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '下料长度允差', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '下料宽度允差', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '下料对角线允差', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '圆度要求', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '错边量', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '棱角度', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '外周长', sortable: false, hiddenSearch: true},
  {
    fixed: 'right',
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]
