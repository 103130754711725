<template>
  <div class="unit-wrap common-tab">
    <el-radio-group v-model="params.radio" class="m-radio-group" @change="radioChange" style="display:none;">
      <el-radio-button v-if="permission('SupplierList')" label="Supplier">供应商</el-radio-button>
      <el-radio-button v-if="permission('CustomerList')" label="Customer">客户</el-radio-button>
    </el-radio-group>
    <FunctionHeader
      v-model="functionHeader.searchVal"
      :tags="functionHeader.tags"
      :search-title="searchTitle"
      :radio="params.radio"
      :columns-key="columnsKey"
      :import-name="functionHeader.importName"
      :export-name="functionHeader.exportName"
      :export-params="functionHeader.exportParams"
      @search="$refs.mTable.setTableData(true)"
    />
    <MTable v-if="permission(params.radio + 'List')" ref="mTable" :columns="columns[params.radio]" :height="height" :columns-key="columnsKey" :set-data-method="getTableData">
      <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
      <div slot="type" slot-scope="{ row }">
        <div>{{ row.type === 0 ? '寄售' : row.type === 1 ?  '自有' :'-' }}</div>
      </div>
      <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
        <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
        <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
      </div>
      <div slot="action" slot-scope="{ row }">
        <el-button v-if="permission(params.radio + 'Status')" type="text" @click="switchStatus(row, params.radio)">{{ row.isEnabled ? '停用' : '启用' }}</el-button>
        <el-divider v-if="permission(params.radio + 'Status')" direction="vertical" />
        <el-button v-if="permission(params.radio + 'Modify')" type="text" @click="modifyForm(row, 'modify')">修改</el-button>
        <el-divider v-if="permission(params.radio + 'Modify')" direction="vertical" />
        <DelPopover v-if="permission(params.radio + 'Del')" @onOk="(callback) => del(row, callback)" />
      </div>
    </MTable>

    <MDialog v-model="visible" :title="messages[currentType]" @onOk="submitForm">
      <MFormBuilder ref="formBuild" :form-data="formData" :form-list="formList[params.radio]" >
        <div slot="drawing">
          <el-upload
            ref="upload"
            class="upload-demo"
            :file-list="fileList"
            v-bind="uploadProp"
            >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">支持扩展名：.xls .xlsx</div>
          </el-upload>
        </div>
      </MFormBuilder>
    </MDialog>
  </div>
</template>

<script>
/** write by luomingzhi */
import FunctionHeader from '@/components/FunctionHeader/index'
import DelPopover from '@/components/DelPopover/index'
import columns from './columns'
import formList from './form-list'
import api from '@/api/information/unit'
import conventionMixin from '@/mixin/conventional-page'
import { getToken } from '@/utils/auth'

export default {
  name: 'UnitInfo',
  components: { FunctionHeader, DelPopover },
  mixins: [conventionMixin],
  data() {
    return {
      api,
      params: {
        radio: 'Supplier'
      },
      functionHeader: {
        searchVal: '',
        tags: [
          {
            hidden: !this.permission('SupplierAdd'),
            tagName: 'el-button',
            props: { type: 'primary', size: 'small' },
            style: { width: 'auto', background: '#607FFF' },
            innerText: '新增',
            on: {
              click: () => {
                this.add()
                this.createCode()
              }
            }
          }
        ],
        importName: 'supplier',
        exportName: 'exportSupplier',
        exportParams: {}
      },
      columns,
      visible: false,
      formList: formList,
      formData: {},
      currentType: 'add',
      searchTitle: '请输入供应商名称',
      fileList: [],
      uploadProp: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        accept: ".xls,.xlsx",
        headers: { token: getToken() },
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onRemove: this.fileRemove,
        onError: this.fileUploadChange,
      },
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 235 - 10 + 40
    }
  },
  created(){
    this.params.radio = this.$route.meta.nameFlage ? this.$route.meta.nameFlage : 'Supplier'
    const currentUrl = window.location.origin
    if (currentUrl == 'http://58.246.65.68:8201') {
      this.uploadProp.action = `${process.env.VUE_APP_SCENCE_API}api/web/v1/basicData/private/file/upload`
    } else {
      this.uploadProp.action = `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`
    }
  },
  mounted() {
    if(this.params.radio === 'Customer'){
      this.functionHeader.exportName = 'exportCustomer'
      this.functionHeader.importName = 'customer'
    }else{
      this.functionHeader.exportName = 'exportSupplier'
      this.functionHeader.importName = 'supplier'
    }
  },
  methods: {
    async createCode() {
      if (sessionStorage.getItem('memberCode') !== 'mubai') return false
      const res = await api[`create${this.params.radio}Code`]({
        count: 1
      })
      if (res) {
        this.$refs.formBuild.setForm({
          code: res[0]
        })
      }
    },
    radioChange(label) {
      const flag = label === 'Supplier'
      this.functionHeader.tags[0].hidden = !this.permission(label + 'Add')
      Object.assign(this.functionHeader, {
        importName: flag ? 'supplier' : 'customer',
        exportName: flag ? 'exportSupplier' : 'exportCustomer'
      })
      switch (this.params.radio) {
        case 'Supplier': this.searchTitle = '请输入供应商名称'; break
        case 'Customer': this.searchTitle = '请输入客户名称'; break
        default: break
      }
      this.$refs.mTable.setTableData(true)
    },
    createSetData(raw) {
      return {
        code: raw.code,
        name: raw.name,
        address: raw.address,
        tel: raw.tel,
        contact: raw.contact,
        mail: raw.mail,
        isEnabled: raw.isEnabled,
        remark: raw.remark,
        downloadTemplate: JSON.stringify(this.fileList),
        type:raw.type
      }
    },
    createResetData(raw) {
      const obj = {
        id: raw.id
      }
      return Object.assign(this.createSetData(raw), obj)
    },
    modifyForm(row) {
      this.fileList = JSON.parse(row.downloadTemplate || '[]')
      this.modifyOrCopy(row, 'modify')
    },
    // 上传附件
    fileUploadChange(res, file) {
      if (res.errorCode === 0) {
        this.fileList.push({
          uid: file.uid,
          name: file.name,
          url: res.result
        })
        return this.$message.success('上传成功')
      }
      if (res.errorCode !== 0) {
        return this.$message.error(res.result)
      }
    },
    fileRemove(file, fileList) {
      this.fileList = fileList.length > 0 ? fileList.map(file => {
        return {
          uid: file.uid,
          name: file.name,
          url: file.url
        }
      }) : []
    },
  }
}
</script>

<style scoped lang="scss">
.unit-wrap {
  padding: 10px;
}
</style>
