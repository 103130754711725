<template>
    <div class="materials-wrap">
        <div class="materials-detail-header">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">MBOM详情</span>
      </div>
    </div>
      <div class="bottom">
        <Split :offset.sync="split.offset" :height="splitHeight" >
          <template slot="left">
            <div v-show="isOpenOne" style="height:calc(100% - 40px)">
              <BOMLevel :mySelfShowFlag="false" @nodeClick="setMaterialsClassId" @changeWidth="changeWidths" :materialsBomId="materialsBomId" :type="'mbom'"/>
              <!-- display:flex;margin-top:10px -->
            </div>
            <div v-show="isOpen" style="margin-top:20px">
              <!-- <img src="@/assets/information/bom/warning.png" style="width: 15px;height:15px;margin-top:22px;margin-left:10px"> -->
              <BOMLevel :mySelfShowFlag="false" :materialsBomId="materialsBomId" @changeWidth="changeWidths" :type="'warnMoreM'" :view="true"/>
            </div>
            <div class="divFixed" v-show="isOpen" @click="changeWidths(false)" title="点击收起" style="cursor: pointer;"><i class="el-icon-d-arrow-left"></i></div>
            <div class="divFixed" v-show="!isOpen" @click="changeWidths(true)" title="点击展开" style="cursor: pointer;"><i class="el-icon-d-arrow-right"></i></div>
          </template>
          <template slot="right">
            <div class="materials-info" v-show="showType == 'Materials'">
              <FunctionHeader
                ref="functionHeader"
                v-model="functionHeader.searchVal"
                search-title="请输入名称/编号/规格"
                :tags="functionHeader.tags"
                :export-name="functionHeader.exportName"
                :export-params="functionHeader.exportParams"
                @search="$refs.mTable.setTableData(true)"
              >
              </FunctionHeader>
              <MTable ref="mTable" :columns="columnsDetail" :height="height" :set-data-method="getTableData" @selection-change="selectionChange">
                <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
                <div slot="drawing" slot-scope="{ row }">
                  <el-image
                    v-if="row.picture"
                    :src="row.picture"
                    class="drawing-img"
                    :preview-src-list="[row.picture]"
                  />
                </div>
                <NewPageOpen v-if="isShowChild" slot="code" slot-scope="{ row }" :path="{ name: 'bomDetail', query: { id: row.id, type: 'view' }}" :text="row.code" />
                <div slot="isEnabled" slot-scope="{ row }" class="is-enabled">
                  <div :style="{background: row.isEnabled ? '#24CAD7' : '#FFCC78'}" />
                  <div>{{ row.isEnabled ? '启用' : '停用' }}</div>
                </div>
                <div slot="action" slot-scope="{ row }">
                  <NewPageOpen v-if="permission('Modify')" :path="{ name: 'materialsDetail', query: { id: row.id, type: 'modify' }}" text="编辑" />
                </div>
              </MTable>

    </div>
              <div class="process-detail-body" v-show="showType == 'Process'">
      <div class="right1">
        <div class="detail-card">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <template>
              <el-row class="view-list" :gutter="24">
                <el-col
                  v-for="(item, index) in getBaseColumns"
                  :key="item.prop"
                  :title="getValue(item)"
                  :span="8"
                  :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex'}"
                >
                  <div style="word-break: keep-all">{{ item.label }}：</div>
                  <div style="word-break: break-all">{{ getValue(item) }}</div>
                </el-col>
              </el-row>
            </template>
          </div>
        </div>
        <div class="detail-card">
            <div class="card-tit">工艺路线图</div>
              <div class="card-bot process-card">
                <process-flow ref="processFlow" :base-form="$refs.baseForm" :read-only="true" :process-flow-detail="processFlowVersion" :handle-type="params.type" :can-import="baseFormData.isEnabled" @addDetail="addDetails" @hideLoading="buttonLoading=false" />
              </div>
        </div>
      </div>
            </div>
              <div v-show="showType == 'Procedure'">
                  <ProcedureDetail ref="procedureDetail"/>
              </div>
          </template>
        </Split>
      </div>
    </div>
  </template>

  <script>
  import FunctionHeader from '@/components/FunctionHeader'
  import SearchForm from '@/components/SearchForm/SearchForm'
  import { columnsDetail,base, station, fileAndDrawings } from './columns'
  import maApi from '@/api/information/materials-attribute'
  import api from '@/api/information/bom'
  import { getUUid } from '@/utils'
  import { Encrypt } from '@/utils/sercet'
  import Split from '@/components/Split/Split'
  import BOMLevel from '@/components/BOMClass/BOMLevel'
  import NewPageOpen from '@/components/NewPageOpen/NewPageOpen'
  import batchExportFront from '@/mixin/batch-export-front'
  import fixCommonPage from '@/mixin/fix-common-page'
  import detailMixin from './detail-mixin'
  import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
  import CURDSelect from '@/components/CURDSelect/CURDSelect'
  import ProcedureParams from '@/components/ProcedureParams/ProcedureParams'
  import procedureApi from '@/api/information/production/procedure'
  import ProcedureDetail from './components/procedureDetail'
  export default {
    name: 'mbomDetail',
    components: { NewPageOpen, BOMLevel, Split, FunctionHeader, SearchForm,ProcessFlow,CURDSelect, ProcedureParams,ProcedureDetail},
    mixins: [batchExportFront, fixCommonPage,detailMixin],
    data() {
      return {
        functionHeader: {
          searchVal: '',
          tags: [],
          importName: 'materials',
          exportName: 'exportMBomLevelDownMList',
          exportParams: {}
        },
        columnsDetail,
        searchFormData: {},
        searchFormatData: [],
        split: {
          offset: 25
        },
        materialsClassId: '',
        defaultSearch: {
          searchVal: Encrypt(JSON.stringify(
            [{
              id: getUUid(),
              fieldName: 'isEnabled',
              fieldType: 'number',
              fieldValue: 1,
              operator: 'eq'
            }]
          ))
        },
        materialsBomId:+this.$route.query.id,
        showType:'Materials',
        saveButton:false,
        columnss: {
        bases: base,
        station,
        drawings: fileAndDrawings,
        files: fileAndDrawings
      },
      issList: [{
        value: 0,
        name: '工序任务无需下发，拥有权限的工位均可操作'
      },
      {
        value: 1,
        name: '工序任务必须下发至拥有权限的工位才可进行操作'
      }
      ],
      assList: [{
        value: 0,
        name: '工序任务无需指派，拥有权限人员均可操作'
      },
      {
        value: 1,
        name: '工序任务必须指派至拥有权限的用户/用户组才可进行操作'
      }
      ],
      differenceType: undefined,
      differenceMinus: undefined,
      differencePlus: undefined,
      selectParams: {
        issueMode: 0,
        assignMode: 0
      },
      procedureDifferenceTypes: [{
        id: 0,
        name: '数值'
      },
      {
        id: 1,
        name: '百分比'
      }],
      stationList: [],
      drawingsList: [],
      filesList: [],
      groupList: [],
      operationDesc: '',
      procedureType: 0,
      isOpen:true,
      isOpenOne:true,
      processFlowClassName:'',
      }
    },
    computed: {
      height() {
        return this.$store.state.app.contentHeight - 235 - 39
      },
      splitHeight() {
        return this.$store.state.app.contentHeight - 235 + 70 - 39
      },
      memberCode() {
      return sessionStorage.getItem('memberCode')
    },
    getBaseColumns() {
        const specificProps = {
            '物料': ['materialsClassName'],
            '物料分类': ['materialsFullName'],
            '通用': ['materialsClassName','materialsFullName']
        }
        const filteredProps = specificProps[this.processFlowClassName] || []
        if (localStorage.getItem('memberType') !== 'line') {
            filteredProps.push('isPartialOutbound', 'overProductionType')
        }
        return this.columns.base.filter(item => !filteredProps.includes(item.prop))
    },
    },
    beforeRouteLeave(to, from, next) {
      if (to.name !== 'refresh') {
        this.$store.dispatch('tagsView/addCachedView', from)
      }
      next()
    },
    // beforeMount() {
    //   getFunctionList({
    //     allCodeChange: this.allCodeChange
    //   })
    // },
    mounted() {
        // this.getProcedureDetailById(140)
    //   this.getMaterialsTypeList()
    //   this.getMaterialsAttributeList()
    },
    methods: {
        async getProcedureDetailById(id) {
      const res = await procedureApi.getProcedureDetail(id)
      if (res) {
        this.baseFormData = res
        this.procedureType = res.type
        this.outsourceTime = res.outsourceTime
        this.stationList = res.stationList
        this.operationDesc = res.operationDesc
        this.drawingsList = res.drawing ? JSON.parse(res.drawing) : []
        this.qualityInspectionPlan = {
          inspectionProjectGroupFirst: {
            isFullInspection: res.isFullInspection,
            isMustCheck: res.isMustCheck,
            isNeedInspect: res.isNeedFirstInspect,
            inspectId: res.firstInspectId,
            inspectEntryMap: res.firstInspectEntryMap || {},
            inspectAttachments: res.firstInspectAttachments,
            inspectorIdList: res.firstInspectorIdList
          },
          inspectionProjectGroupProcess: {
            isFullInspection: res.isFullInspection,
            isMustCheck: res.isMustCheck,
            isNeedInspect: res.isNeedProcessInspect,
            inspectId: res.processInspectId,
            inspectEntryMap: res.processInspectEntryMap || {},
            inspectAttachments: res.processInspectAttachments,
            inspectorIdList: res.processInspectorIdList
          }
        }
        this.filesList = res.attachment ? JSON.parse(res.attachment) : []
        this.groupList = res.params ? JSON.parse(res.params) : []
        this.selectParams = {
          issueMode: res.issueMode,
          assignMode: res.assignMode
        }
        this.differenceMinus = res.differenceMinus
        this.differencePlus = res.differencePlus
        this.differenceType = res.differenceType === -1 ? '' : res.differenceType
      }
      if (this.params.type === 'copy') await this.createProcedureCode()
    },
        fixData(data) {
      return data.filter((item, index) => index < 5)
    },
    changeWidths(val){
        this.split.offset = val ? 25 : 3
        this.isOpen =  val ? true : false
      this.isOpenOne = this.isOpen
    },
      back() {
        this.$router.back()
      },
      setMaterialsClassId(id,val) {
        this.materialsClassId = id
        if(!val.type){
            this.showType='Materials'
            this.$refs.mTable.setTableData(true)
        }else if(val.type == 2){
            this.showType='Process'
            this.getProcessDetailById(val.materialsId,val.processFlowId)
        }else{
            this.showType='Procedure'
            this.$refs.procedureDetail.getProcedureDetailById(val.materialsId)
            this.$refs.procedureDetail.getProcedureIdProcess()
        }
      },
      // 获取物料类型
      async getMaterialsTypeList() {
        const res = await maApi.getMaterialsTypeList(this.defaultSearch)
        if (res) {
          this.searchFormList[1].children[0].options = res
        }
      },
    //   // 获取物料属性
    //   async getMaterialsAttributeList() {
    //     const res = await maApi.getMaterialsAttributeList()
    //     if (res) {
    //       this.searchFormList[2].children[0].options = res
    //     }
    //   },
      // 获取物料数据
      async getTableData(condition, callback) {
        if(this.materialsClassId){
            const { page, search, order } = condition
        const searchCondition = {
            materialsBomLevelId: this.materialsClassId,
            search: this.functionHeader.searchVal
        }
        this.functionHeader.exportParams = searchCondition
        const res = await api.getMBomDownLevelList({
            materialsBomLevelId:this.materialsClassId,
          page: page.currentPage,
          size: page.pageSize,
          ...searchCondition
        })
        if (res) {
          callback({
            total: res.total,
            content: res.records
          })
        }
          callback()
        }else{
            callback()
        }
      },
      // 查询物料
      searchTable(data) {
        this.searchFormatData = data
        this.$refs.mTable.setTableData(true)
      },
      add() {
        this.$router.push({ name: 'materialsDetail', query: { type: 'add' }})
      },
      // 更多操作
      more(row, command) {
        const functions = {
          modify: () => {
            this.modifyOrCopy(row, command)
          },
          copy: () => {
            this.modifyOrCopy(row, command)
          },
          del: () => {
            this.del(row)
          },
          switch: () => {
            this.switch(row)
          }
        }
        functions[command]()
      },
      modifyOrCopy(row, command) {
        this.$router.push({ name: 'materialsDetail', query: { id: row.id, type: command }})
      },
      async batchDel() {
        const res = await api.delBatchMaterials(this.exportSelectionIds)
        if (res) {
          this.$message.success('删除成功')
          this.$refs.mTable.tableRefs().clearSelection()
          this.$refs.mTable.setTableData(true)
        }
      },
      // 删除物料
      del(row) {
        this.currentType = 'del'
        this.$confirm('您确定要删除吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async() => {
          const res = await api.delMaterials({ id: row.id })
          if (res) {
            this.$message.success('删除成功')
            this.$refs.mTable.setTableData()
          }
        })
      },
      // 更改物料状态
      async switch(row) {
        const res = await api.switchStatus(row.isEnabled ? 'disable' : 'enable', 'materials', row.id)
        if (res) {
          this.$message.success(`${row.name}${row.isEnabled ? '停用' : '启用'}成功`)
          this.$refs.mTable.setTableData()
        }
      },
      viewDetail(row) {
        this.$message.info('敬请期待！')
      },
      onSuccess() {
        this.$refs.mTable.setTableData()
      }
    }
  }
  </script>

  <style scoped lang="scss">
  .function-header-wrap {
    padding-top: 0;
  }

  .divFixed {
    position: absolute;
    bottom: 0;
    text-align: end;
    width: 100%;
    height: 40px;
    line-height: 40px;
    padding-right: 10px;
    background: #d3deea;
    box-sizing: border-box;
  }

  .materials-wrap {
    width: 100%;
    .materials-detail-header {
    width: 100%;
    padding: 10px 0;
  }
    .bottom {
      width: 100%;
      height: 100%;
      padding: 10px;
      background: #fff;
      .materials-info {
        padding: 10px;
      }
    }
  }
  .drawing-img {
    width: 50px;
    height: 48px;
    display: block;
    cursor: pointer;
    color: #fff;
  }
  ::v-deep {
    .params-card {
      .body {
        padding: 0 !important;
      }
      .params {
        width: 100% !important;
      }
      .el-form-item__label {
        padding: 0 12px 0 0 !important;
      }
    }
  }
  </style>
