<template>
  <div class="process-detail-wrapper">
    <div class="process-detail-header flex-sbc">
      <div class="left" @click="back()">
        <img src="@/assets/information/procedure/左滑@2x.png" style="width: 8px">
        <span style="padding-left: 10px;cursor: pointer">用料清单详情</span>
      </div>
    </div>

    <div class="process-detail-body flex-sb">
      <div class="right">
        <div class="detail-card">
          <div class="card-tit">基本信息</div>
          <div class="card-bot">
            <template>
              <el-row type="flex" justify="start" style="flex-flow:row wrap" class="view-list" :gutter="24">
                <el-col
                  v-for="(item, index) in getBaseColumns"
                  :key="item.prop"
                  :title="getValue(item)"
                  :span="6"
                  :style="{marginBottom: index !== 6 ? '20px' : 0, display: 'inline-flex'}"
                >
                  <div style="word-break: keep-all">{{ item.label }}：</div>
                  <div style="word-break: break-all">{{ getValue(item) }}
                    <span v-if="item.prop === 'workOrderCompleteRate' || item.prop === 'keyMaterialsCompleteRate'">%</span>
                  </div>
                </el-col>
              </el-row>
            </template>
          </div>
        </div>
        <div class="detail-card">
          <el-tabs v-model="activeTabName" class="detail-tabs" type="border-card" @tab-click="switchTab">
            <el-tab-pane label="工序物料" name="工序物料">
              <div class="">

                <FunctionHeader
                  ref="functionHeader"
                  v-model="functionProcessHeader.searchVal"
                  :tags="functionProcessHeader.tags"
                  search-title="请输入物料名称/编号/规格"
                  :export-name="functionProcessHeader.exportName"
                  :export-params="functionProcessHeader.exportParams"
                  @search="getProcedureMaterialsListPage()"
                >
                  <el-button type="primary" size="small" style="height:32px" @click="batchExport()">全部导出</el-button>
                  <el-button type="primary" size="small" style="height:32px" @click="batchReceive('add',true)">领料
                  </el-button>
                  <el-select v-model="processId" clearable placeholder="选择工序" @change="selectProcess"
                             style="margin-left:10px">
                    <el-option
                      v-for="item in processList"
                      :key="item.procedureId"
                      :label="item.procedureFullName"
                      :value="item.procedureId"
                    />
                  </el-select>
                  <!--<el-select v-model="moduleId" clearable placeholder="选择模组" @change="selectModule"-->
                             <!--style="margin-left:10px;display:block" multiple>-->
                    <!--<el-option-->
                      <!--v-for="item in moduleList"-->
                      <!--:key="item.materialsId"-->
                      <!--:label="item.materialsFullName"-->
                      <!--:value="item.materialsId"-->
                    <!--/>-->
                  <!--</el-select>-->
                </FunctionHeader>
                <div class="m-table">
                  <el-table ref="table" :data="dataProcess" :show-header="true" :height="tableHeight"
                            :default-expand-all="isExpand">
                    <el-table-column type="expand">
                      <template slot-scope="props">
                        <el-table :ref="`expandTableRef${props.$index}`" :data="props.row.children"
                                  class="ellipsis-table" row-key="id"
                                  @selection-change="(data) => handleSelectionChange(data, props.$index)">
                          <el-table-column type="selection" :reserve-selection="true" :selectable="selectable">
                          </el-table-column>
                          <el-table-column prop="index" label="序号">
                            <template slot-scope="scope">
                              {{ scope.$index + 1 }}
                            </template>
                          </el-table-column>
                          <el-table-column prop="materialsType" label="物料类型">
                            <template slot-scope="scope">
                              {{ scope.row.materialsType }}
                            </template>
                          </el-table-column>
                          <el-table-column prop="materialsCode" label="物料编号"></el-table-column>
                          <el-table-column prop="materialsName" label="物料名称" width="200"></el-table-column>
                          <el-table-column prop="specifications" label="物料规格"></el-table-column>

                          <el-table-column prop="isKeyMaterials" label="是否关键物料">
                            <template slot-scope="scope">
                              {{ scope.row.isKeyMaterials ? '是' : '否' }}
                            </template>
                          </el-table-column>
                          <el-table-column prop="mainUnitName" label="主单位"></el-table-column>
                          <el-table-column prop="requirementQuantity" label="需求数量"></el-table-column>
                          <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>

                          <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
                          <el-table-column prop="appliedUnissuedQuantity" label="申请未发数量"></el-table-column>

                          <el-table-column prop="stockQuantity" label="库存数"></el-table-column>
                          <el-table-column prop="allocatedStockQuantity" label="分配数"></el-table-column>

                          <el-table-column prop="requireDate" label="需求日期"></el-table-column>
                          <!-- <el-table-column prop="lockedQuantity" label="锁库数量"></el-table-column> -->
                          <!--<el-table-column prop="availableStockQuantity" label="自由库存"></el-table-column>-->
                        </el-table>
                      </template>
                    </el-table-column>
                    <el-table-column prop="procedureName" label="工序名称"></el-table-column>
                    <el-table-column prop="procedureLocationCode" label="工序定位码"></el-table-column>
                    <el-table-column prop="requirementQuantity" label="需求数量"></el-table-column>
                    <el-table-column prop="receivedQuantity" label="已领数量"></el-table-column>
                    <el-table-column prop="notReceiveQuantity" label="未领数量"></el-table-column>
                    <el-table-column prop="allocatedStockQuantity" label="分配数"></el-table-column>
                    <el-table-column prop="supplyQuantity" label="供应数"></el-table-column>
                    <el-table-column prop="sitCompleteRate" label="线边齐套率">
                      <template slot-scope="scope">
                        {{ scope.row.sitCompleteRate}}%
                      </template>
                    </el-table-column>
                    <el-table-column prop="completeRate" label="齐套率">
                      <template slot-scope="scope">
                        {{ scope.row.completeRate}}%
                      </template>
                    </el-table-column>
                    <el-table-column prop="keyMaterialsRequirementQuantity" label="关键料数量"></el-table-column>
                    <el-table-column prop="keyMaterialsSitCompleteRate" label="关键料线边齐套率">
                      <template slot-scope="scope">
                        {{ scope.row.keyMaterialsSitCompleteRate}}%
                      </template>
                    </el-table-column>
                    <el-table-column prop="keyMaterialsCompleteRate" label="关键料齐套率">
                      <template slot-scope="scope">
                        {{ scope.row.keyMaterialsCompleteRate}}%
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
                <div class="page-container">
                  <el-pagination
                    :current-page="page.currentPage"
                    :page-sizes="page.pageSizes"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="page.total"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                  />
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="工单物料" name="工单物料" :disabled="['add','copy'].includes(params.type)">
              <div class="bottom">
                <FunctionHeader
                  ref="functionHeader"
                  v-model="functionHeader.searchVal"
                  search-title="请输入名称/编号/规格"
                  :tags="functionHeader.tags"
                  :export-name="functionHeader.exportName"
                  :export-params="functionHeader.exportParams"
                  @search="$refs.mTable.setTableData(true)"
                >
                </FunctionHeader>
                <MTable ref="mTable" :columns="columnsWork" :set-data-method="getWorkTableData"
                        @selection-change="selectionChange" :columns-setting="false">
                  <div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>
                  <div slot="completeRate" slot-scope="{ row }">{{ row.completeRate }}%</div>
                  <div slot="isKeyMaterials" slot-scope="{ row }">{{ row.isKeyMaterials === 1 ? '是': '否' }}</div>
                  <!--<div slot="action" slot-scope="{ row }">-->
                  <!--<el-button type="text" @click="occupy(row, params.radio)">锁库</el-button>-->
                  <!--<el-divider direction="vertical" />-->
                  <!--<el-button type="text" :disabled="!row.lockedQuantity" @click="cancelOccupy(row, params.radio)">取消</el-button>-->
                  <!--</div>-->
                </MTable>

                <!--<MDialog v-model="visible" title="物料锁库操作" @onOk="submitForm">-->
                  <!--<MTable ref="mTables" :columns="columnsOccupyMaterials" :data="occupyList" :columns-setting="false"-->
                          <!--:show-page="false" :height="height">-->
                    <!--<div slot="index" slot-scope="{ $index }">{{ $index + 1 }}</div>-->
                    <!--<div slot="materialsType" slot-scope="{ row }">-->
                      <!--<div>{{ row.materialsType }}</div>-->
                    <!--</div>-->
                    <!--<div slot="lockedQuantity" slot-scope="{ row }">-->
                      <!--<el-input-number v-model="row.lockQuantity" :min="0" :max="row.maxLockQuantity"-->
                                       <!--controls-position="right" :step="1" style="width: 90%"></el-input-number>-->
                    <!--</div>-->
                  <!--</MTable>-->
                <!--</MDialog>-->
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import detailMixin from './detail-mixin'
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import BOM from '@/components/ProcessFlow/BOM'
import FunctionHeader from '@/components/FunctionHeader/index'

export default {
  name: 'materialsRequireDetail',
  components: { ProcessFlow, BOM, FunctionHeader },
  mixins: [detailMixin],
  data() {
    return {
      activeTabName: '工序物料',
      showPage: true
    }
  },
  async mounted() {
    this.params = this.$route.query
    await this.getProcessDetailById(this.params.id, this.params.type)
    await this.getProcedureMaterialsListPage()
    await this.getProcessList()
    await this.getModuleList()
  },
  methods: {
    switchTab(tab, e) {
    }
  }
}
</script>

<style scoped lang="scss">
.process-detail-wrapper {
  height: 100%;

  .process-detail-header {
    width: 100%;
    padding: 10px 0;
  }

  .process-detail-body {
    width: 100%;

    ::v-deep {
      .el-select__tags-text {
        display: inline-block;
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .el-tag__close.el-icon-close {
        top: -7px;
      }
    }

    //   height: calc(100% - 60px);
    .left {
      height: 100%;
      width: 242px;
      margin-right: 20px;
      display: inline-block;
      overflow-y: auto;
      overflow-x: hidden;

      .history-version {
        height: 70px;
        padding: 14px;
        width: 100%;
        background: #ffffff;
        border-radius: 4px 4px 0 0;
        display: flex;
        align-items: center;
      }

      ul,
      li {
        list-style: none;
        padding: 0;
        margin: 0;
        font-size: 12px;
        color: #9597ae;
        background: #ffffff;
        border-radius: 0 0 4px 4px;
      }

      li {
        height: 70px;
        padding: 14px;

        .li-left {
          width: 100%;
          height: 100%;
          display: inline-flex;
          flex-direction: column;
          justify-content: space-between;
          position: relative;

          .name {
            font-size: 14px;
            color: #41456b;
          }

          .info {
            position: relative;
            left: -6px;
          }

          .dropdown {
            position: absolute;
            right: -20px;
            top: 13px;

            .el-icon-more-btn {
              transform: rotate(90deg);
              background: #e9eaec;
              border-radius: 2px;
              padding: 0 10px;
            }
          }
        }

        &:hover {
          background: #f0f2f5;
        }

        &.active {
          background: #f0f2f5;
        }
      }
    }

    .right {
      height: 100%;
      flex: 1;
      transition: all 0.5s;
      overflow: auto;

      .detail-card {
        margin-bottom: 20px;
      }

      .process-card {
        padding: 10px;
      }
    }

    .page-container {
      width: 100%;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
    }

    .m-table {
      position: relative;

      ::v-deep {
        .el-table .cell {
          padding-left: 10px !important;
        }
      }
    }
  }
}
</style>
