var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "process-detail-wrapper" }, [
    _c("div", { staticClass: "process-detail-header flex-sbc" }, [
      _c(
        "div",
        {
          staticClass: "left",
          on: {
            click: function($event) {
              return _vm.back()
            }
          }
        },
        [
          _c("img", {
            staticStyle: { width: "8px" },
            attrs: {
              src: require("@/assets/information/procedure/左滑@2x.png")
            }
          }),
          _c(
            "span",
            { staticStyle: { "padding-left": "10px", cursor: "pointer" } },
            [_vm._v("用料清单详情")]
          )
        ]
      )
    ]),
    _c("div", { staticClass: "process-detail-body flex-sb" }, [
      _c("div", { staticClass: "right" }, [
        _c("div", { staticClass: "detail-card" }, [
          _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
          _c(
            "div",
            { staticClass: "card-bot" },
            [
              [
                _c(
                  "el-row",
                  {
                    staticClass: "view-list",
                    staticStyle: { "flex-flow": "row wrap" },
                    attrs: { type: "flex", justify: "start", gutter: 24 }
                  },
                  _vm._l(_vm.getBaseColumns, function(item, index) {
                    return _c(
                      "el-col",
                      {
                        key: item.prop,
                        style: {
                          marginBottom: index !== 6 ? "20px" : 0,
                          display: "inline-flex"
                        },
                        attrs: { title: _vm.getValue(item), span: 6 }
                      },
                      [
                        _c(
                          "div",
                          { staticStyle: { "word-break": "keep-all" } },
                          [_vm._v(_vm._s(item.label) + "：")]
                        ),
                        _c(
                          "div",
                          { staticStyle: { "word-break": "break-all" } },
                          [
                            _vm._v(_vm._s(_vm.getValue(item)) + " "),
                            item.prop === "workOrderCompleteRate" ||
                            item.prop === "keyMaterialsCompleteRate"
                              ? _c("span", [_vm._v("%")])
                              : _vm._e()
                          ]
                        )
                      ]
                    )
                  }),
                  1
                )
              ]
            ],
            2
          )
        ]),
        _c(
          "div",
          { staticClass: "detail-card" },
          [
            _c(
              "el-tabs",
              {
                staticClass: "detail-tabs",
                attrs: { type: "border-card" },
                on: { "tab-click": _vm.switchTab },
                model: {
                  value: _vm.activeTabName,
                  callback: function($$v) {
                    _vm.activeTabName = $$v
                  },
                  expression: "activeTabName"
                }
              },
              [
                _c(
                  "el-tab-pane",
                  { attrs: { label: "工序物料", name: "工序物料" } },
                  [
                    _c(
                      "div",
                      {},
                      [
                        _c(
                          "FunctionHeader",
                          {
                            ref: "functionHeader",
                            attrs: {
                              tags: _vm.functionProcessHeader.tags,
                              "search-title": "请输入物料名称/编号/规格",
                              "export-name":
                                _vm.functionProcessHeader.exportName,
                              "export-params":
                                _vm.functionProcessHeader.exportParams
                            },
                            on: {
                              search: function($event) {
                                return _vm.getProcedureMaterialsListPage()
                              }
                            },
                            model: {
                              value: _vm.functionProcessHeader.searchVal,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.functionProcessHeader,
                                  "searchVal",
                                  $$v
                                )
                              },
                              expression: "functionProcessHeader.searchVal"
                            }
                          },
                          [
                            _c(
                              "el-button",
                              {
                                staticStyle: { height: "32px" },
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.batchExport()
                                  }
                                }
                              },
                              [_vm._v("全部导出")]
                            ),
                            _c(
                              "el-button",
                              {
                                staticStyle: { height: "32px" },
                                attrs: { type: "primary", size: "small" },
                                on: {
                                  click: function($event) {
                                    return _vm.batchReceive("add", true)
                                  }
                                }
                              },
                              [_vm._v("领料 ")]
                            ),
                            _c(
                              "el-select",
                              {
                                staticStyle: { "margin-left": "10px" },
                                attrs: {
                                  clearable: "",
                                  placeholder: "选择工序"
                                },
                                on: { change: _vm.selectProcess },
                                model: {
                                  value: _vm.processId,
                                  callback: function($$v) {
                                    _vm.processId = $$v
                                  },
                                  expression: "processId"
                                }
                              },
                              _vm._l(_vm.processList, function(item) {
                                return _c("el-option", {
                                  key: item.procedureId,
                                  attrs: {
                                    label: item.procedureFullName,
                                    value: item.procedureId
                                  }
                                })
                              }),
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "m-table" },
                          [
                            _c(
                              "el-table",
                              {
                                ref: "table",
                                attrs: {
                                  data: _vm.dataProcess,
                                  "show-header": true,
                                  height: _vm.tableHeight,
                                  "default-expand-all": _vm.isExpand
                                }
                              },
                              [
                                _c("el-table-column", {
                                  attrs: { type: "expand" },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(props) {
                                        return [
                                          _c(
                                            "el-table",
                                            {
                                              ref:
                                                "expandTableRef" + props.$index,
                                              staticClass: "ellipsis-table",
                                              attrs: {
                                                data: props.row.children,
                                                "row-key": "id"
                                              },
                                              on: {
                                                "selection-change": function(
                                                  data
                                                ) {
                                                  return _vm.handleSelectionChange(
                                                    data,
                                                    props.$index
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("el-table-column", {
                                                attrs: {
                                                  type: "selection",
                                                  "reserve-selection": true,
                                                  selectable: _vm.selectable
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "index",
                                                  label: "序号"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                scope.$index + 1
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "materialsType",
                                                  label: "物料类型"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                scope.row
                                                                  .materialsType
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "materialsCode",
                                                  label: "物料编号"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "materialsName",
                                                  label: "物料名称",
                                                  width: "200"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "specifications",
                                                  label: "物料规格"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "isKeyMaterials",
                                                  label: "是否关键物料"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(scope) {
                                                        return [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                scope.row
                                                                  .isKeyMaterials
                                                                  ? "是"
                                                                  : "否"
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "mainUnitName",
                                                  label: "主单位"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "requirementQuantity",
                                                  label: "需求数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "receivedQuantity",
                                                  label: "已领数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "notReceiveQuantity",
                                                  label: "未领数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop:
                                                    "appliedUnissuedQuantity",
                                                  label: "申请未发数量"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "stockQuantity",
                                                  label: "库存数"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop:
                                                    "allocatedStockQuantity",
                                                  label: "分配数"
                                                }
                                              }),
                                              _c("el-table-column", {
                                                attrs: {
                                                  prop: "requireDate",
                                                  label: "需求日期"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "procedureName",
                                    label: "工序名称"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "procedureLocationCode",
                                    label: "工序定位码"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "requirementQuantity",
                                    label: "需求数量"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "receivedQuantity",
                                    label: "已领数量"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "notReceiveQuantity",
                                    label: "未领数量"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "allocatedStockQuantity",
                                    label: "分配数"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "supplyQuantity",
                                    label: "供应数"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "sitCompleteRate",
                                    label: "线边齐套率"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row.sitCompleteRate
                                              ) +
                                              "% "
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "completeRate",
                                    label: "齐套率"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(scope.row.completeRate) +
                                              "% "
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "keyMaterialsRequirementQuantity",
                                    label: "关键料数量"
                                  }
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "keyMaterialsSitCompleteRate",
                                    label: "关键料线边齐套率"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row
                                                  .keyMaterialsSitCompleteRate
                                              ) +
                                              "% "
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                }),
                                _c("el-table-column", {
                                  attrs: {
                                    prop: "keyMaterialsCompleteRate",
                                    label: "关键料齐套率"
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "default",
                                      fn: function(scope) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                scope.row
                                                  .keyMaterialsCompleteRate
                                              ) +
                                              "% "
                                          )
                                        ]
                                      }
                                    }
                                  ])
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "page-container" },
                          [
                            _c("el-pagination", {
                              attrs: {
                                "current-page": _vm.page.currentPage,
                                "page-sizes": _vm.page.pageSizes,
                                "page-size": _vm.page.pageSize,
                                layout:
                                  "total, sizes, prev, pager, next, jumper",
                                total: _vm.page.total
                              },
                              on: {
                                "size-change": _vm.handleSizeChange,
                                "current-change": _vm.handleCurrentChange
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "el-tab-pane",
                  {
                    attrs: {
                      label: "工单物料",
                      name: "工单物料",
                      disabled: ["add", "copy"].includes(_vm.params.type)
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "bottom" },
                      [
                        _c("FunctionHeader", {
                          ref: "functionHeader",
                          attrs: {
                            "search-title": "请输入名称/编号/规格",
                            tags: _vm.functionHeader.tags,
                            "export-name": _vm.functionHeader.exportName,
                            "export-params": _vm.functionHeader.exportParams
                          },
                          on: {
                            search: function($event) {
                              return _vm.$refs.mTable.setTableData(true)
                            }
                          },
                          model: {
                            value: _vm.functionHeader.searchVal,
                            callback: function($$v) {
                              _vm.$set(_vm.functionHeader, "searchVal", $$v)
                            },
                            expression: "functionHeader.searchVal"
                          }
                        }),
                        _c("MTable", {
                          ref: "mTable",
                          attrs: {
                            columns: _vm.columnsWork,
                            "set-data-method": _vm.getWorkTableData,
                            "columns-setting": false
                          },
                          on: { "selection-change": _vm.selectionChange },
                          scopedSlots: _vm._u([
                            {
                              key: "index",
                              fn: function(ref) {
                                var $index = ref.$index
                                return _c("div", {}, [
                                  _vm._v(_vm._s($index + 1))
                                ])
                              }
                            },
                            {
                              key: "completeRate",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(_vm._s(row.completeRate) + "%")
                                ])
                              }
                            },
                            {
                              key: "isKeyMaterials",
                              fn: function(ref) {
                                var row = ref.row
                                return _c("div", {}, [
                                  _vm._v(
                                    _vm._s(
                                      row.isKeyMaterials === 1 ? "是" : "否"
                                    )
                                  )
                                ])
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            )
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }