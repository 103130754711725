var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderAdd" },
    [
      _c("div", { staticClass: "detail-card" }, [
        _c("div", { staticClass: "card-tit" }, [_vm._v("基本信息")]),
        _c(
          "div",
          { staticClass: "card-bot" },
          [
            ["add", "addOrder"].includes(_vm.$route.query.type)
              ? _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-form-inline",
                    attrs: {
                      "label-position": "right",
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "110px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "领料单号", prop: "code" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "",
                                    disabled: ["view"].includes(
                                      _vm.$route.query.type
                                    )
                                  },
                                  model: {
                                    value: _vm.ruleForm.code,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "code", $$v)
                                    },
                                    expression: "ruleForm.code"
                                  }
                                }),
                                ["add", "addOrder"].includes(
                                  _vm.$route.query.type
                                )
                                  ? _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "22px",
                                          right: "0"
                                        },
                                        attrs: { type: "text" },
                                        on: { click: _vm.getCode }
                                      },
                                      [_vm._v("自动生成")]
                                    )
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "领料单名称", prop: "name" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "",
                                    disabled: ["view"].includes(
                                      _vm.$route.query.type
                                    )
                                  },
                                  model: {
                                    value: _vm.ruleForm.name,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "name", $$v)
                                    },
                                    expression: "ruleForm.name"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "需求时间",
                                  prop: "requireDate"
                                }
                              },
                              [
                                _c("el-date-picker", {
                                  staticStyle: { width: "100%" },
                                  attrs: {
                                    clearable: false,
                                    type: "datetime",
                                    "value-format": "yyyy-MM-dd HH:mm:ss",
                                    placeholder: "选择需求时间"
                                  },
                                  model: {
                                    value: _vm.ruleForm.requireDate,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "requireDate", $$v)
                                    },
                                    expression: "ruleForm.requireDate"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "单据类型",
                                  prop: "receiptType"
                                }
                              },
                              [
                                _c(
                                  "el-select",
                                  {
                                    attrs: {
                                      placeholder: "请选择",
                                      disabled: ["view", "add"].includes(
                                        _vm.$route.query.type
                                      )
                                    },
                                    on: { change: _vm.chooseType },
                                    model: {
                                      value: _vm.ruleForm.receiptType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.ruleForm,
                                          "receiptType",
                                          $$v
                                        )
                                      },
                                      expression: "ruleForm.receiptType"
                                    }
                                  },
                                  _vm._l(_vm.docType, function(item) {
                                    return _c("el-option", {
                                      key: item.id,
                                      attrs: {
                                        label: item.name,
                                        value: item.id
                                      }
                                    })
                                  }),
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "工单编号",
                                  prop: "receiptNumber"
                                }
                              },
                              [
                                ["view", "add"].includes(_vm.$route.query.type)
                                  ? _c("el-input", {
                                      attrs: {
                                        placeholder: "",
                                        disabled: true
                                      },
                                      model: {
                                        value: _vm.ruleForm.receiptNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "receiptNumber",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.receiptNumber"
                                      }
                                    })
                                  : _vm._e(),
                                ["addOrder"].includes(_vm.$route.query.type)
                                  ? _c("m-remote-select", {
                                      staticClass: "input-prepend",
                                      attrs: {
                                        searchType: "receiptId",
                                        placeholder: "请选择工单编号",
                                        "interface-fun":
                                          _vm.api.getReceiptListPage,
                                        options: _vm.orderLists,
                                        receiptType: _vm.ruleForm.receiptType,
                                        filterable: "",
                                        clearable: "",
                                        "label-name": "receiptNumber",
                                        "search-name": "receiptNumber",
                                        "value-name": "receiptId"
                                      },
                                      on: {
                                        "update:options": function($event) {
                                          _vm.orderLists = $event
                                        },
                                        "on-change": _vm.workChange
                                      },
                                      model: {
                                        value: _vm.ruleForm.receiptNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.ruleForm,
                                            "receiptNumber",
                                            $$v
                                          )
                                        },
                                        expression: "ruleForm.receiptNumber"
                                      }
                                    })
                                  : _vm._e()
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "工单名称" } },
                              [
                                _c("el-input", {
                                  attrs: { placeholder: "", disabled: true },
                                  model: {
                                    value: _vm.ruleForm.receiptName,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "receiptName", $$v)
                                    },
                                    expression: "ruleForm.receiptName"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: _vm.mode ? "订单" : "项目" } },
                              [
                                _c("m-remote-select", {
                                  staticClass: "input-prepend",
                                  attrs: {
                                    searchType: "projectId",
                                    searchId: _vm.searchId,
                                    placeholder: "",
                                    "interface-fun":
                                      _vm.processApi.getProjectListPage,
                                    options: _vm.projectList,
                                    filterable: "",
                                    clearable: "",
                                    "label-name": "projectName",
                                    "search-name": "projectName",
                                    "value-name": "projectId",
                                    disabled: true
                                  },
                                  on: {
                                    "update:options": function($event) {
                                      _vm.projectList = $event
                                    },
                                    "on-change": _vm.projectChange
                                  },
                                  model: {
                                    value: _vm.ruleForm.projectId,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "projectId", $$v)
                                    },
                                    expression: "ruleForm.projectId"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 18 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "备注" } },
                              [
                                _c("el-input", {
                                  attrs: {
                                    placeholder: "",
                                    disabled: ["view"].includes(
                                      _vm.$route.query.type
                                    )
                                  },
                                  model: {
                                    value: _vm.ruleForm.remark,
                                    callback: function($$v) {
                                      _vm.$set(_vm.ruleForm, "remark", $$v)
                                    },
                                    expression: "ruleForm.remark"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              : _c(
                  "el-form",
                  {
                    ref: "ruleForm",
                    staticClass: "demo-form-inline",
                    attrs: {
                      "label-position": "right",
                      model: _vm.ruleForm,
                      rules: _vm.rules,
                      "label-width": "110px"
                    }
                  },
                  [
                    _c(
                      "el-row",
                      {
                        staticStyle: { "flex-flow": "row wrap" },
                        attrs: { type: "flex", justify: "start" }
                      },
                      _vm._l(_vm.getBaseColumns, function(item) {
                        return _c(
                          "el-col",
                          { key: item.key, attrs: { span: 6 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: item.name } },
                              [
                                _c("div", [
                                  _vm._v(_vm._s(_vm.getDetailVal(item.key)))
                                ])
                              ]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
          ],
          1
        )
      ]),
      this.type !== "view"
        ? _c(
            "el-button",
            {
              staticStyle: { "margin-bottom": "8px" },
              attrs: { type: "primary", size: "small" },
              on: { click: _vm.addMaterials }
            },
            [_vm._v("增加物料")]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "detail-card", staticStyle: { padding: "10px" } },
        [
          _vm.type != "view"
            ? _c("MTable", {
                ref: "mTable",
                attrs: {
                  columns: _vm.productionTaskColumn,
                  height: 500,
                  data: _vm.materialsList,
                  "show-page": false
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "index",
                      fn: function(ref) {
                        var $index = ref.$index
                        return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                      }
                    },
                    {
                      key: "materialsType",
                      fn: function(ref) {
                        var row = ref.row
                        return _c("div", {}, [
                          _vm._v(" " + _vm._s(row.materialsType) + " ")
                        ])
                      }
                    },
                    {
                      key: "planOutputQuantity",
                      fn: function(ref) {
                        var row = ref.row
                        return _c(
                          "div",
                          {},
                          [
                            _c("el-input-number", {
                              staticStyle: { width: "90%" },
                              attrs: {
                                min: 0,
                                "controls-position": "right",
                                step: 1
                              },
                              model: {
                                value: row.applyQuantity,
                                callback: function($$v) {
                                  _vm.$set(row, "applyQuantity", $$v)
                                },
                                expression: "row.applyQuantity"
                              }
                            })
                          ],
                          1
                        )
                      }
                    },
                    {
                      key: "planTime",
                      fn: function(ref) {
                        var row = ref.row
                        return [
                          _c("div", [_vm._v(_vm._s(row.planTime.join(" 至 ")))])
                        ]
                      }
                    },
                    {
                      key: "action",
                      fn: function(ref) {
                        var $index = ref.$index
                        return [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "text" },
                              on: {
                                click: function($event) {
                                  return _vm.del($index)
                                }
                              }
                            },
                            [_vm._v("删除")]
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  1937085684
                )
              })
            : _c(
                "div",
                { staticClass: "bottom" },
                [
                  _c("FunctionHeader", {
                    ref: "functionHeader",
                    attrs: {
                      "search-title": "请输入物料名称/编号/规格",
                      tags: _vm.functionHeader.tags,
                      "export-name": _vm.functionHeader.exportName,
                      "export-params": _vm.functionHeader.exportParams
                    },
                    on: {
                      search: function($event) {
                        return _vm.$refs.mTable.setTableData(true)
                      }
                    },
                    model: {
                      value: _vm.functionHeader.searchVal,
                      callback: function($$v) {
                        _vm.$set(_vm.functionHeader, "searchVal", $$v)
                      },
                      expression: "functionHeader.searchVal"
                    }
                  }),
                  _c("MTable", {
                    ref: "mTable",
                    attrs: {
                      columns: _vm.applyColumn,
                      height: 500,
                      "set-data-method": _vm.getTableData,
                      "columns-setting": false
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "index",
                        fn: function(ref) {
                          var $index = ref.$index
                          return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                        }
                      },
                      {
                        key: "materialsType",
                        fn: function(ref) {
                          var row = ref.row
                          return _c("div", {}, [
                            _vm._v(" " + _vm._s(row.materialsType) + " ")
                          ])
                        }
                      },
                      {
                        key: "planOutputQuantity",
                        fn: function(ref) {
                          var row = ref.row
                          return _c(
                            "div",
                            {},
                            [
                              _c("el-input-number", {
                                staticStyle: { width: "90%" },
                                attrs: {
                                  min: 0,
                                  max: row.maxLockQuantity,
                                  "controls-position": "right",
                                  step: 1
                                },
                                model: {
                                  value: row.lockQuantity,
                                  callback: function($$v) {
                                    _vm.$set(row, "lockQuantity", $$v)
                                  },
                                  expression: "row.lockQuantity"
                                }
                              })
                            ],
                            1
                          )
                        }
                      },
                      {
                        key: "planTime",
                        fn: function(ref) {
                          var row = ref.row
                          return [
                            _c("div", [
                              _vm._v(_vm._s(row.planTime.join(" 至 ")))
                            ])
                          ]
                        }
                      },
                      {
                        key: "action",
                        fn: function(ref) {
                          var $index = ref.$index
                          return [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.del($index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
        ],
        1
      ),
      _c("div", { staticClass: "order-footer" }, [
        _c("div"),
        _c(
          "div",
          { staticClass: "footer-ri" },
          [
            _c("el-button", { on: { click: _vm.returnList } }, [
              _vm._v(_vm._s(this.type !== "view" ? "取 消" : "返 回"))
            ]),
            this.type !== "view"
              ? _c(
                  "el-button",
                  { attrs: { type: "primary" }, on: { click: _vm.save } },
                  [_vm._v("确 定")]
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _c("div", { staticClass: "lines" }),
      _c("select-process-materials", {
        ref: "selectMaterialInventory",
        attrs: {
          visible: _vm.logVisible,
          "receipt-id": _vm.ruleForm.receiptId,
          "process-flow-id": _vm.ruleForm.processFlowId,
          "process-flow-version": _vm.ruleForm.processFlowVersionId,
          "select-process-materials": _vm.selectMaterials,
          "receipt-type": _vm.ruleForm.receiptType,
          title: "领料单新增物料"
        },
        on: {
          "update:visible": function($event) {
            _vm.logVisible = $event
          },
          submitForm: _vm.submitForm
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }