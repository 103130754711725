/** write by luomingzhi */
import { status } from '@/config/options.config'
import { formatColumns } from '@/utils'
const setColumns = (name) => ([
  {
    prop: 'index',
    label: '序号',
    slotName: 'index',
    sortable: false,
    width: 80,
    hiddenSearch: true
  },
  {
    prop: 'code',
    label: `${name}编码`,
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'name',
    label: `${name}名称`,
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input' }
  },
  {
    prop: 'type',
    label: `类型`,
    sortable: false,
    hiddenSearch: true,
    slotName:'type',
    form: {
      tagName: 'el-select',
      options: [
        {
         id:0,
         name:'寄售'
        },
        {
          id:1,
          name:'自有'
         },
  ],
      required: false
    }
  },
  {
    prop: 'address',
    label: '收货地址',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'contact',
    label: '联系人',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'tel',
    label: '联系电话',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'mail',
    label: '邮箱',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input',
      required: false }
  },
  {
    prop: 'isEnabled',
    label: `${name}状态`,
    slotName: 'isEnabled',
    sortable: false,
    filters: formatColumns(status),
    form: {
      tagName: 'el-select',
      defaultValue: 1,
      options: status,
      required: false
    }
  },
  {
    prop: 'remark',
    label: '备注',
    sortable: false,
    hiddenSearch: true,
    form: { tagName: 'el-input', required: false, props: { type: 'textarea' }}
  },
  {
    prop: 'downloadTemplate',
    label: '模板',
    sortable: false,
    hiddenSearch: true,
    slotName: 'downloadTemplate',
    form: {
      colSpan: 24,
      tagName: 'div',
      required: false,
      customSlots: ['drawing']
    }
  },
  {
    slotName: 'action',
    label: '操作',
    fixed: 'right',
    sortable: false,
    hiddenSearch: true,
    width: 145
  }
])
const Customer = setColumns('客户').filter(column => column.prop !== 'type')
const Supplier = setColumns('供应商')

export default {
  Supplier,
  Customer
}
