<template>
  <div class="orderAdd">
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-form
          v-if="['add','addOrder'].includes($route.query.type)"
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          label-width="110px"
          class="demo-form-inline"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="领料单号" prop="code">
                <el-input
                  v-model="ruleForm.code"
                  placeholder=""
                  :disabled="['view'].includes($route.query.type)"
                />
                <el-button
                  v-if="['add','addOrder'].includes($route.query.type)"
                  style="position:absolute;top: 22px;right:0"
                  type="text"
                  @click="getCode"
                >自动生成</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="领料单名称" prop="name">
                <el-input
                  v-model="ruleForm.name"
                  placeholder=""
                  :disabled="['view'].includes($route.query.type)"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="需求时间" prop="requireDate">
                <el-date-picker
                  v-model="ruleForm.requireDate"
                  :clearable="false"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择需求时间"
                  style="width:100%"
                />
              </el-form-item>
            </el-col>

            <el-col :span="6">
              <el-form-item label="单据类型" prop="receiptType">
                <el-select v-model="ruleForm.receiptType" placeholder="请选择" :disabled="['view','add'].includes($route.query.type)" @change="chooseType">
                  <el-option
                    v-for="item in docType"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工单编号" prop="receiptNumber">
                <el-input
                  v-model="ruleForm.receiptNumber"
                  placeholder=""
                  :disabled="true"
                  v-if="['view','add'].includes($route.query.type)"
                />
                <m-remote-select
                v-if="['addOrder'].includes($route.query.type)"
                v-model="ruleForm.receiptNumber"
                class="input-prepend"
                :searchType="'receiptId'"
                :placeholder="'请选择工单编号'"
                :interface-fun="api.getReceiptListPage"
                :options.sync="orderLists"
                :receiptType="ruleForm.receiptType"
                filterable
                clearable
                :label-name="'receiptNumber'"
                :search-name="'receiptNumber'"
                :value-name ="'receiptId'"
                @on-change="workChange"
              />
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="工单名称">
                <el-input
                  v-model="ruleForm.receiptName"
                  placeholder=""
                  :disabled="true"
                />
              </el-form-item>
            </el-col>
            <el-col :span="6">
                <el-form-item :label="mode ? '订单' : '项目'">
                  <m-remote-select
                  v-model="ruleForm.projectId"
                  class="input-prepend"
                  :searchType="'projectId'"
                  :searchId="searchId"
                  :placeholder="''"
                  :interface-fun="processApi.getProjectListPage"
                  :options.sync="projectList"
                  filterable
                  clearable
                  :label-name="'projectName'"
                  :search-name="'projectName'"
                  :value-name ="'projectId'"
                  :disabled="true"
                  @on-change="projectChange"
                />
                </el-form-item>
              </el-col>
            <el-col :span="18">
              <el-form-item label="备注" >
                <el-input
                  v-model="ruleForm.remark"
                  placeholder=""
                  :disabled="['view'].includes($route.query.type)"
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form
          v-else
          ref="ruleForm"
          label-position="right"
          :model="ruleForm"
          :rules="rules"
          label-width="110px"
          class="demo-form-inline"
        >
          <!-- 详情 -->
          <el-row type="flex" justify="start" style="flex-flow:row wrap">
            <el-col v-for="item in getBaseColumns" :key="item.key" :span="6">
              <el-form-item :label="item.name">
                <div>{{ getDetailVal(item.key) }}</div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </div>
    <el-button v-if="this.type!=='view'" type="primary" size="small" @click="addMaterials" style="margin-bottom:8px">增加物料</el-button>
    <div class="detail-card" style="padding:10px">
        <MTable
          ref="mTable"
          :columns="productionTaskColumn"
          :height="500"
          :data="materialsList"
          :show-page="false"
          v-if="type!= 'view'"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="materialsType" slot-scope="{row }">
            {{ row.materialsType }}
          </div>
          <div slot="planOutputQuantity" slot-scope="{ row }">
                      <el-input-number v-model="row.applyQuantity" :min="0" controls-position="right" :step="1"  style="width: 90%"></el-input-number>
                  </div>
          <template slot="planTime" slot-scope="{ row }">
            <div>{{ row.planTime.join(' 至 ') }}</div>
          </template>

          <template slot="action" slot-scope="{ $index }">
            <el-button type="text" @click="del($index)">删除</el-button>
          </template>

        </MTable>
        <div class="bottom" v-else>
                  <FunctionHeader
                  ref="functionHeader"
                  v-model="functionHeader.searchVal"
                  search-title="请输入物料名称/编号/规格"
                  :tags="functionHeader.tags"
                  :export-name="functionHeader.exportName"
                  :export-params="functionHeader.exportParams"
                  @search="$refs.mTable.setTableData(true)"
                  >
                  </FunctionHeader>
        <MTable
          ref="mTable"
          :columns="applyColumn"
          :height="500"
          :set-data-method="getTableData"
          :columns-setting="false"
        >
          <div slot="index" slot-scope="{$index }">{{ $index + 1 }}</div>
          <div slot="materialsType" slot-scope="{row }">
            {{ row.materialsType }}
          </div>
          <div slot="planOutputQuantity" slot-scope="{ row }">
                      <el-input-number v-model="row.lockQuantity" :min="0" :max="row.maxLockQuantity" controls-position="right" :step="1"  style="width: 90%"></el-input-number>
                  </div>
          <template slot="planTime" slot-scope="{ row }">
            <div>{{ row.planTime.join(' 至 ') }}</div>
          </template>

          <template slot="action" slot-scope="{ $index }">
            <el-button type="text" @click="del($index)">删除</el-button>
          </template>

        </MTable>
        </div>
    </div>
    <div class="order-footer">
      <div />
      <div class="footer-ri">
        <el-button @click="returnList">{{ this.type!=='view'?'取 消':'返 回' }}</el-button>
        <el-button v-if="this.type!=='view'" type="primary" @click="save">确 定</el-button>
      </div>
    </div>
    <div class="lines" />
        <!-- 工序物料 -->
        <select-process-materials
      ref="selectMaterialInventory"
      :visible.sync="logVisible"
      :receipt-id="ruleForm.receiptId"
      :process-flow-id="ruleForm.processFlowId"
      :process-flow-version="ruleForm.processFlowVersionId"
      :select-process-materials="selectMaterials"
      :receipt-type="ruleForm.receiptType"
      :title="'领料单新增物料'"
      @submitForm="submitForm"
    />
  </div>
</template>
<script>
import api from '@/api/information/materials-apply'
import processApi from '@/api/information/production/process'
import { detailBaseFields, productionTaskColumn,applyColumn } from './columns'
import { base } from '@/views/information/production/process/detail/columns'
import mapi from '@/api/information/materials-require'
import { materialsRequisitionStatus, docType } from '@/config/options.config'
import FunctionHeader from '@/components/FunctionHeader/index'
import SelectProcessMaterials from '../components/SelectProcessMaterials.vue'
import dayjs from 'dayjs'
export default {
  name: 'materialsApplyDetail',
  components: { FunctionHeader,SelectProcessMaterials },
  data() {
    return {
      api,
      processApi,
      logVisible: false,
      productionTaskColumn,
      orderLists:[],
      projectList:[],
      docType,
      ruleForm: {
        code: '',
        name: '',
        receiptId:undefined,
        receiptType:0,
        receiptNumber: '',
        receiptName: '',
        projectId:undefined,
        processFlowId:undefined,
        processFlowVersionId:undefined,
        remark:'',
        requireDate: dayjs(new Date()).format('YYYY-MM-DD 00:00:00')
      },
      rules: {
        code: [
          { required: true, message: '请输入领料单号', trigger: 'blur' }
        ],
        requireDate: [
          { required: true, message: '请选择需求时间', trigger: 'blur' }
        ],
        receiptType: [
          { required: true, message: '请选择单据类型', trigger: 'blur' }
        ],
        receiptNumber:[
        { required: true, message: '请选择单据单号', trigger: 'blur' }
        ]
      },
      baseFormData: {},
      columnsBase: base,
      detailBaseFields,
      materialsList:[],
      applyColumn,
      functionHeader: {
      searchVal: '',
      importName: '',
      exportName: '',
      exportParams: {},
      tags: [
        {
          hidden: !this.permission('Export'),
          tagName: 'el-button',
          props: { type: 'primary', size: 'small' },
          style: { width: 'auto', background: '#607FFF'},
          innerText: '全部导出',
          on: {
            click: () => this.batchExport()
          }
        }
      ]
    },
    expandedRows:[],
    selectMaterials:[],
    searchId:undefined
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == 'materialsApplyList') {
      this.$store.dispatch('tagsView/delCachedView', to)
    }
    next()
  },

  async created() {
    this.type = this.$route.query.type
    switch (this.type) {
      case 'view':
        await this.getDetail()
        break
      case 'add':
      await this.getReceiptDetail()
      await this.getCode()
        break
      case 'addOrder':
      await this.getCode()
        break
      default:
        break
    }
  },
  computed: {
  mode() {
      return this.$store.state.app.mode
    },
    getBaseColumns(){
      if (this.mode) {
      return this.detailBaseFields.map(item => {
        const newLabel = item.name ? item.name.replace(/项目/g, '订单') :''
        return { ...item, name: newLabel }
      })
    } else {
      return this.detailBaseFields
    }
  }
  },
  mounted(){
    this.materialsList = this.type === 'add'&& localStorage.getItem('applyMaterials') ? JSON.parse(localStorage.getItem('applyMaterials')):[]
  },
  methods: {
    async batchExport() {
      const params = {
        id: this.$route.query.id,
        search: this.functionHeader.searchVal
      }
      const res = await api.exportApplyMaterialsList(params)
      if (res && res.data.byteLength) {
        const urlP = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/octet-stream;charset=ISO8859-1'
          })
        )
        const fileName = `${dayjs().format('YYYYMMDDHHmmss')}.xls`
        this.downloadFile(urlP, fileName)
      }
    },
    downloadFile(urlP, name) {
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = urlP
      link.setAttribute('download', name)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    getMaxNum(row){
      return row.notReceiveQuantity - row.appliedUnissuedQuantity
    },
    chooseType(val){
     this.ruleForm.receiptName = ''
     this.ruleForm.receiptId = undefined
     this.ruleForm.receiptNumber = ''
    },
    workChange(val,val1){
      if(val1.length){
        this.ruleForm.receiptName = val1[0].receiptName
        this.ruleForm.receiptId = val1[0].receiptId
        this.ruleForm.processFlowId  = val1[0].processFlowId
        this.ruleForm.processFlowVersionId = val1[0].processFlowVersionId
        this.ruleForm.projectId = val1[0].projectId
        this.searchId = val1[0].projectId
      }else{
        this.ruleForm.receiptName = ''
        this.ruleForm.receiptId = undefined
        this.ruleForm.projectId = undefined
        this.ruleForm.processFlowId  = undefined
        this.ruleForm.processFlowVersionId = undefined
        this.searchId = undefined
      }
    },
    projectChange(val,val1){
          if(val1.length){
            this.ruleForm.projectId = val1[0].projectId
          }else{
            this.ruleForm.projectId = this.ruleForm.projectId ? this.ruleForm.projectId:undefined
            this.searchId =  this.ruleForm.projectId ? this.ruleForm.projectId:undefined
          }
        },
      async submitForm(val) {
        const res = await api.synchronizedApplyQuantity({
          receiptId:this.ruleForm.receiptId,
          itemList:val.map(item =>{
            return {
              procedureId:item.procedureId,
              procedureLocationCode:item.procedureLocationCode,
              materialsId:item.materialsId
            }
          })
        })
        if(res){
          let arr = res.map(item=>{
          return {
            ...item,
            applyQuantity:item.notReceiveQuantity - item.appliedUnissuedQuantity
          }
        })
        this.materialsList = arr.filter(item =>item.applyQuantity > 0)
        this.logVisible = false
        }
      },
    addMaterials(){
      if(!this.ruleForm.receiptNumber){
        this.$message.error('单据不可为空')
        return
      }
        this.selectMaterials = this.materialsList
        this.logVisible = true
    },
    async getReceiptDetail(){
      const res = await mapi.getMaterialsRequireDetail(
        {
           receiptId:this.$route.query.id,
           receiptType:this.$route.query.receiptType
          })
          if (res) {
            const { receiptId,receiptType,receiptNumber,receiptName,projectId,projectNumber,projectName,processFlowId,processFlowVersionId } = res
            this.ruleForm.receiptId = receiptId
            this.ruleForm.receiptType = receiptType
            this.ruleForm.receiptNumber = receiptNumber
            this.ruleForm.receiptName = receiptName
            this.ruleForm.projectId = projectId
            this.ruleForm.projectNumber = projectNumber
            this.ruleForm.projectName = projectName
            this.ruleForm.processFlowId = processFlowId
            this.ruleForm.processFlowVersionId = processFlowVersionId
      }
    },
    // 获取详情物料表
    async getTableData(condition, callback) {
      const { page } = condition
      const searchCondition = {
        search: this.functionHeader.searchVal,
        id: this.$route.query.id,
      }
      this.functionHeader.exportParams = searchCondition
      const res = await api.getMaterialsDetailListPage({
        page: page.currentPage,
        size: page.pageSize,
        ...searchCondition
      })
      if (res) {
        callback({
          total: res.total,
          content: res.records
        })
      }
      callback()
    },
    del(index){
    this.materialsList.splice(index, 1)
  },
  // 获取领料单基本信息值
    getDetailVal(key) {
      const result = this.ruleForm[key] === 0 || this.ruleForm[key] ? this.ruleForm[key]:'-'
      if (key === 'receiptType') {
        const current = docType.find(item => item.id === result)
      return current && current.name
      }else if(key === 'receiptStatus'){
        const current = materialsRequisitionStatus.find(item => item.id === result)
      return current && current.name
      }
    return result
    },
    // 获取详情
    async getDetail() {
      const res = await api.getMaterialsApplyBaseDetail({id:this.$route.query.id})
      if (res) {
        this.ruleForm = {
          ...res
        }
      }
    },
    // 获取记录编号
    async getCode() {
      const res = await api.getMaterialsApplyCode({directionType:0})
      if (res) {
        this.ruleForm.code = res
      }
    },
    save() {
      this.$refs.ruleForm.validate(async(valid) => {
        if (valid) {
          let obj = {
             ...this.ruleForm,
             directionType:0,
            itemList:this.materialsList.map(item=>{
             return {
              procedureLocationCode:item.procedureLocationCode,
              procedureId:item.procedureId,
              materialsId:item.materialsId,
              applyQuantity:item.applyQuantity
             }
            })
          }
          const res = await api.addMaterialsApply(obj)
          if (res) {
            this.$message.success('新增成功')
            this.$router.push({ name: 'materialsApplyList'})
          }
        } else {
          return false
        }
      })
    },
    returnList() {
      this.$router.go(-1)
      localStorage.removeItem('applyMaterials')
    }
  }
}
</script>

<style scoped lang="scss">
::v-deep .my-col {
  .el-form-item {
    margin-bottom: 2px;
    .el-form-item__label {
      padding-right: 0;
    }
  }
}
::v-deep {
  .el-tag {
    margin-right: 10px;
    margin-bottom: 5px;
  }
  .el-form-item {
    margin-bottom: 20px;
  }
  .el-date-editor .el-range-separator,
  .el-date-editor .el-range__icon {
    line-height: 26px;
  }
  .el-input-number {
    height: 32px;
    line-height: 32px;
    width: 100%;
  }
  .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
  .el-input-number.is-controls-right .el-input-number__increase,
  .el-input-number.is-controls-right .el-input-number__decrease {
    line-height: 15px;
  }
}
.orderAdd {
  width: 100%;
  height: 100%;
  .input-new-tag {
    width: 130px;
  }
  .button-new-tag {
    height: 32px;
  }
  .detail-card {
    margin-bottom: 20px;
  }
  .lines {
    width: 100%;
    height: 20px;
    background: #f0f2f5;
  }
  ::v-deep .el-select {
    width: 100%;
  }
  .e-btn {
    margin-bottom: 10px;
  }
  .order-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100;
    background: #fff;
    border-top: 1px solid #dde3eb;
    .footer-le {
      padding-left: 30px;
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #393d60;
      span {
        margin-right: 45px;
        display: inline-block;
      }
    }
    .footer-ri {
      margin-right: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
