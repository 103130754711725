export const columns = [
  {
    prop: 'index',
    label: '序号',
    fixed: 'left',
    width: 80,
    slotName: 'index',
    hiddenSearch: true
  },
  {prop: 'name', label: '工单编号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '物料编号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '物料名称', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '焊缝号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '母材材质', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '母材厚度', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '焊缝工艺规程编号', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '焊接方法', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '标准', sortable: false, hiddenSearch: true},
  {prop: 'name', label: '规程使用状态', sortable: false, hiddenSearch: true},
  {
    fixed: 'right',
    prop: 'action',
    label: '操作',
    hiddenSearch: true,
    sortable: false,
    slotName: 'action'
  }
]
