var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrap-wrap" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c(
            "el-row",
            {
              staticStyle: {
                padding: "10px 10px 10px 10px",
                "border-bottom": "1px solid #dde3eb"
              }
            },
            [
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-input",
                    {
                      staticStyle: { width: "95%" },
                      attrs: { type: "text", placeholder: "工艺规程编号" },
                      on: { change: _vm.topSearch },
                      model: {
                        value: _vm.topSearchForm.number,
                        callback: function($$v) {
                          _vm.$set(_vm.topSearchForm, "number", $$v)
                        },
                        expression: "topSearchForm.number"
                      }
                    },
                    [_vm._v("<")]
                  )
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 5 } },
                [
                  _c(
                    "el-button",
                    {
                      staticStyle: { background: "#607FFF" },
                      attrs: { type: "primary", size: "small" },
                      on: { click: _vm.topSearch }
                    },
                    [_vm._v("查询")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { size: "small" },
                      on: { click: _vm.resetTopSearch }
                    },
                    [_vm._v("重置")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "bottom" },
        [
          _c("FunctionHeader", {
            ref: "functionHeader",
            attrs: {
              "columns-key": "TTT-GYGC",
              "search-title": "请输入工艺规程编号搜索",
              "import-name": "GYGCImport",
              tags: !_vm.exportSelectionIds.length
                ? _vm.functionHeader.tags
                : []
            },
            on: {
              search: function($event) {
                return _vm.$refs.mTable.setTableData(true)
              }
            },
            model: {
              value: _vm.functionHeader.searchVal,
              callback: function($$v) {
                _vm.$set(_vm.functionHeader, "searchVal", $$v)
              },
              expression: "functionHeader.searchVal"
            }
          }),
          _c("MTable", {
            ref: "mTable",
            attrs: {
              "columns-key": "TTT-GYGC",
              columns: _vm.columns,
              height: _vm.height,
              "set-data-method": _vm.getTableData
            },
            scopedSlots: _vm._u([
              {
                key: "index",
                fn: function(ref) {
                  var $index = ref.$index
                  return _c("div", {}, [_vm._v(_vm._s($index + 1))])
                }
              },
              {
                key: "diagramUrl",
                fn: function(ref) {
                  var row = ref.row
                  return _c(
                    "el-button",
                    {
                      attrs: { type: "text" },
                      on: {
                        click: function($event) {
                          return _vm.viewDetail(row)
                        }
                      }
                    },
                    [_vm._v(" 查看 ")]
                  )
                }
              },
              {
                key: "action",
                fn: function(ref) {
                  var row = ref.row
                  var $index = ref.$index
                  return _c(
                    "div",
                    {},
                    [
                      _vm.permission("Edit")
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle("edit", row)
                                  }
                                }
                              },
                              [_vm._v("编辑")]
                            )
                          ]
                        : _vm._e(),
                      _vm.permission("Del")
                        ? [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle("del", row)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: { title: _vm.currentType, width: "1000px", top: "30px" },
          on: { onOk: _vm.addOrEditSubmit },
          model: {
            value: _vm.visiable,
            callback: function($$v) {
              _vm.visiable = $$v
            },
            expression: "visiable"
          }
        },
        [
          _c(
            "h3",
            { staticStyle: { "margin-top": "0", "margin-bottom": "10px" } },
            [_vm._v("基本信息")]
          ),
          _c(
            "el-row",
            { staticStyle: { padding: "10px" }, attrs: { gutter: 20 } },
            [
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("*编码")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.code,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "code", $$v)
                            },
                            expression: "dataForAdd.code"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("*工艺规程编号")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.number,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "number", $$v)
                            },
                            expression: "dataForAdd.number"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("工艺评定号")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.evaluationNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "evaluationNumber", $$v)
                            },
                            expression: "dataForAdd.evaluationNumber"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 16 }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: {
                        display: "flex",
                        "justify-content": "flex-start",
                        "align-items": "center"
                      }
                    },
                    [
                      _c(
                        "label",
                        {
                          staticStyle: {
                            width: "100px",
                            "text-align": "right",
                            "padding-right": "10px",
                            "line-height": "32px",
                            "font-size": "14px"
                          }
                        },
                        [_vm._v(" *焊工资格 ")]
                      ),
                      _c(
                        "div",
                        { staticStyle: { flex: "1" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "98%" },
                              attrs: {
                                filterable: "",
                                multiple: "",
                                "collapse-tags": "",
                                placeholder: "焊工资格"
                              },
                              model: {
                                value: _vm.dataForAdd.welderProjectIds,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.dataForAdd,
                                    "welderProjectIds",
                                    $$v
                                  )
                                },
                                expression: "dataForAdd.welderProjectIds"
                              }
                            },
                            _vm._l(_vm.allXM, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item.name }
                              })
                            }),
                            1
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("母材材质")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.baseMaterial,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "baseMaterial", $$v)
                            },
                            expression: "dataForAdd.baseMaterial"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("母材厚度1")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.firstThickness,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "firstThickness", $$v)
                            },
                            expression: "dataForAdd.firstThickness"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("母材厚度2")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.secondThickness,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "secondThickness", $$v)
                            },
                            expression: "dataForAdd.secondThickness"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("预热温度(℃)")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.preheatTemperature,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dataForAdd,
                                "preheatTemperature",
                                $$v
                              )
                            },
                            expression: "dataForAdd.preheatTemperature"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("层间温度(℃)")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.interlayerTemperature,
                            callback: function($$v) {
                              _vm.$set(
                                _vm.dataForAdd,
                                "interlayerTemperature",
                                $$v
                              )
                            },
                            expression: "dataForAdd.interlayerTemperature"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("焊后热处理")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.heatTreatment,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "heatTreatment", $$v)
                            },
                            expression: "dataForAdd.heatTreatment"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [_vm._v("标准")]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c("el-input", {
                          staticStyle: { width: "95%" },
                          attrs: { type: "text" },
                          model: {
                            value: _vm.dataForAdd.standard,
                            callback: function($$v) {
                              _vm.$set(_vm.dataForAdd, "standard", $$v)
                            },
                            expression: "dataForAdd.standard"
                          }
                        })
                      ],
                      1
                    )
                  ])
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: { "margin-bottom": "20px" },
                  attrs: { span: 8 }
                },
                [
                  _c("div", { staticClass: "ttt" }, [
                    _c("label", { staticClass: "ttt-label" }, [
                      _vm._v("*焊接简图")
                    ]),
                    _c(
                      "div",
                      { staticStyle: { flex: "1" } },
                      [
                        _c(
                          "el-upload",
                          _vm._b(
                            { staticClass: "avatar-uploader" },
                            "el-upload",
                            _vm.uploadProps,
                            false
                          ),
                          [
                            _vm.dataForAdd.diagramUrl
                              ? _c("img", {
                                  staticClass: "avatar",
                                  attrs: { src: _vm.dataForAdd.diagramUrl }
                                })
                              : _c("i", {
                                  staticClass:
                                    "el-icon-plus avatar-uploader-icon"
                                })
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "h3",
            { staticStyle: { "margin-top": "0", "margin-bottom": "10px" } },
            [
              _vm._v(" 焊层信息 "),
              _c(
                "el-button",
                {
                  attrs: {
                    size: "mini",
                    type: "primary",
                    icon: "el-icon-plus"
                  },
                  on: { click: _vm.addLayer }
                },
                [_vm._v(" 添加 ")]
              )
            ],
            1
          ),
          _c(
            "el-row",
            { staticStyle: { padding: "10px" }, attrs: { gutter: 20 } },
            [
              _c("MTable", {
                ref: "mTableyy",
                attrs: {
                  columns: _vm.logColumns,
                  data: _vm.itemList,
                  height: 200
                },
                scopedSlots: _vm._u([
                  {
                    key: "layer",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.layer,
                              callback: function($$v) {
                                _vm.$set(row, "layer", $$v)
                              },
                              expression: "row.layer"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "weldMethod",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.weldMethod,
                              callback: function($$v) {
                                _vm.$set(row, "weldMethod", $$v)
                              },
                              expression: "row.weldMethod"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "weldMaterialNumber",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.weldMaterialNumber,
                              callback: function($$v) {
                                _vm.$set(row, "weldMaterialNumber", $$v)
                              },
                              expression: "row.weldMaterialNumber"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "specifications",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.specifications,
                              callback: function($$v) {
                                _vm.$set(row, "specifications", $$v)
                              },
                              expression: "row.specifications"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "polarity",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.polarity,
                              callback: function($$v) {
                                _vm.$set(row, "polarity", $$v)
                              },
                              expression: "row.polarity"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "current",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.current,
                              callback: function($$v) {
                                _vm.$set(row, "current", $$v)
                              },
                              expression: "row.current"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "voltage",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.voltage,
                              callback: function($$v) {
                                _vm.$set(row, "voltage", $$v)
                              },
                              expression: "row.voltage"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "speed",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.speed,
                              callback: function($$v) {
                                _vm.$set(row, "speed", $$v)
                              },
                              expression: "row.speed"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "shieldingGasType",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.shieldingGasType,
                              callback: function($$v) {
                                _vm.$set(row, "shieldingGasType", $$v)
                              },
                              expression: "row.shieldingGasType"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "gasFlow",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.gasFlow,
                              callback: function($$v) {
                                _vm.$set(row, "gasFlow", $$v)
                              },
                              expression: "row.gasFlow"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "shieldingGasPurity",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.shieldingGasPurity,
                              callback: function($$v) {
                                _vm.$set(row, "shieldingGasPurity", $$v)
                              },
                              expression: "row.shieldingGasPurity"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "backGasFlow",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.backGasFlow,
                              callback: function($$v) {
                                _vm.$set(row, "backGasFlow", $$v)
                              },
                              expression: "row.backGasFlow"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "dryingTemperature",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.dryingTemperature,
                              callback: function($$v) {
                                _vm.$set(row, "dryingTemperature", $$v)
                              },
                              expression: "row.dryingTemperature"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "holdingTime",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.holdingTime,
                              callback: function($$v) {
                                _vm.$set(row, "holdingTime", $$v)
                              },
                              expression: "row.holdingTime"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "dosage",
                    fn: function(ref) {
                      var row = ref.row
                      return _c(
                        "div",
                        {},
                        [
                          _c("el-input", {
                            staticStyle: { width: "95%" },
                            attrs: { type: "text" },
                            model: {
                              value: row.dosage,
                              callback: function($$v) {
                                _vm.$set(row, "dosage", $$v)
                              },
                              expression: "row.dosage"
                            }
                          })
                        ],
                        1
                      )
                    }
                  },
                  {
                    key: "action",
                    fn: function(ref) {
                      var row = ref.row
                      var $index = ref.$index
                      return _c(
                        "div",
                        {},
                        [
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function($event) {
                                    return _vm.onHandle2($index)
                                  }
                                }
                              },
                              [_vm._v("删除")]
                            )
                          ]
                        ],
                        2
                      )
                    }
                  }
                ])
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "MDialog",
        {
          attrs: {
            width: "1000px",
            top: "30px",
            title: "焊接简图",
            showFooter: false
          },
          model: {
            value: _vm.viewVisible,
            callback: function($$v) {
              _vm.viewVisible = $$v
            },
            expression: "viewVisible"
          }
        },
        [_c("img", { attrs: { src: _vm.imgUrl } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }